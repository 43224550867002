import React, { useState, useEffect } from 'react'
import { Hero, PopularDestination, Recommended, TravelStories } from '../components'
import Layout from '../../../hoc/Layout'
import { SubHeader, SuspenseLoader } from '../../../shared'
import { useMediaQuery } from 'react-responsive';
import { getExploresApi, getHotelQuickSearchApi } from '../apiServices';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { setFlightSearch } from '../exploresSlice';
import { Helmet } from "react-helmet";


const Home = ({ flightBgURL, hotelBgURL, lang }) => {
  const dispatch = useDispatch()
  const { flightSearch } = useSelector(state => state.exploresState);
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1024px)" });
  const [selectedCard, setSelectedCard] = useState('flight') // ['flight', 'hotel']
  const { loading } = useSelector(state => state.exploresState)
  const url = new URLSearchParams(useLocation().search)
  const location = useLocation()
  const result = url.get("service")
  useEffect(() => {
    window.scrollTo(0, 0); // scroll to top of page
    if (result) {
      setSelectedCard(result)
    }
  }, [result])

  useEffect(() => {
    dispatch(setFlightSearch({
      ...flightSearch,
      mode: 'ROUNDTRIP',
      searchList: [flightSearch?.searchList[0]].map((item, index) => ({
        ...item,
        returnDate: format(
          new Date(new Date(item.departureDate).getTime() + 24 * 60 * 60 * 1000),
          "yyyy-MM-dd"
        )
      }))
    }))
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0); // scroll to top of page
    dispatch(getExploresApi)
    dispatch(getHotelQuickSearchApi)
  }, [])
  useEffect(() => {
    if (location.pathname === '/flights') {
      setSelectedCard('flight')
    } else if (location.pathname === '/hotels') {
      setSelectedCard('hotel')
    }
  }, [location.pathname])


  return (
    <div className=''>
      <Helmet>
        <meta name="description" content="Flytern - Flight Tickets,Hotels,Packages, Activities" />
        <meta name="keywords" content="website, blog, foo, bar"></meta>
        <title>Flytern</title>
      </Helmet>
      {isDesktopOrLaptop &&
        <SubHeader setSelectedCard={(value) => setSelectedCard(value)} lang={lang} />
      }

      {
        loading ? <SuspenseLoader /> :
          <>

            <Hero application="flight" selectedCard={selectedCard} backgroundImage={selectedCard === "flight" ? flightBgURL : hotelBgURL} lang={lang} />
            <Layout>
              <div className='flex flex-col gap-5 my-5 '>
                <Recommended />
                <PopularDestination />
                <TravelStories />
              </div>
            </Layout>
          </>
      }

    </div>
  )
}

function mapStateToProps(state) {
  return {
    flightBgURL: state?.exploresState?.data?.bgGrounds?.length && state?.exploresState?.data?.bgGrounds[0]['flightBgURL'],
    hotelBgURL: state?.exploresState?.data?.bgGrounds?.length && state?.exploresState?.data?.bgGrounds[0]['hotelBgURL']
  };
}

export default connect(mapStateToProps)(Home);
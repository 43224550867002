import React from 'react'
import { useSelector } from 'react-redux'

const FooterTop = () => {
    const { translation } = useSelector(state => state.sharedState)
    const { genericLoader } = useSelector(state => state.flightState)
    const { saveTravellerLoader } = useSelector(state => state.insuranceState)
    const { paymentWaitLoader } = useSelector(state => state.paymentState)

    return (
        <> {
            genericLoader || saveTravellerLoader || paymentWaitLoader ?
                null :
                <div className=' bg-black lg:grid grid-cols-10 h-[356px] hidden '>
                    <div className='relative col-span-4 '>
                        <img className='w-full h-60' src={require('../../assets/footerone.png')} alt="" srcSet="" />
                        <img className='h-80 w-72 absolute  left-[30%] bottom-0' src={require('../../assets/footerphone.png')} alt="" />
                    </div>
                    <div className="flex flex-col justify-center h-full col-span-6 gap-8 pl-10">
                        <div className='flex flex-col text-white gap-7'>
                            <h3 className='text-3xl font-bold uppercase'>{translation?.download_app}</h3>
                            <h4 className='text-2xl font-medium uppercase'>{translation?.be_informed_about}</h4>
                            <h6 className='text-orange-400 text-[15px] font-normal uppercase'>{translation?.for_free}</h6>
                        </div>
                        <div className='flex gap-5 cursor-pointer'>
                            <a href='https://play.google.com/store/apps/details?id=com.oneglobal.flytern' target='_blank' rel="noreferrer"><img className='w-[184px] h-[50px] rounded-md' src={require('../../assets/googleicon.png')} alt="" /></a>
                            <a href='https://apps.apple.com/us/app/flytern-flights-hotels/id6469104609' target='_blank' rel="noreferrer" >
                                <img className='w-[184px] h-[50px] rounded-md ' src={require('../../assets/appleicon.png')} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
        }
        </>
    )
}

export default FooterTop
import React from 'react'

const SuspenseLoader = () => {
  return (
    <div className='flex items-center justify-center h-screen'>
      <img src={require('../../assets/433b6c5336c72a21bcfd9db8d83156-unscreen.gif')} alt='' className=''  />
    </div>
  )
}

export default SuspenseLoader
import React, { useEffect } from 'react'
import SideBar from '../../../shared/components/SideBar'
import HelpCenterFor from '../components/HelpCenterFor'
import { arabic_translation, generalSidebarData } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const HelpCenter = () => {
    const { selectedLanguageAndCountry } = useSelector(state => state.sharedState)
    const navigate = useNavigate()
    const  { translation} = useSelector((state) =>  state.sharedState)
    return (
        <div className='mt-12'>
            <div className='flex gap-1 text-sm font-normal cursor-pointer text-neutral-400'>
                <h3 className='cursor-pointer' onClick={() => navigate('/')}>{translation?.home}</h3>
                <h3>/</h3>
                <h3 className='font-medium text-black cursor-pointer'>{translation?.help_center}</h3>
            </div>
            <div className="grid grid-cols-10 gap-8 mt-8 mb-8">
                <div className='hidden col-span-2 lg:block'>
                    <SideBar sideBarList={generalSidebarData} />
                </div>
                <div className='col-span-10 lg:col-span-8'>
                    <HelpCenterFor />
                </div>
            </div>
        </div>
    )
}

export default HelpCenter
import React, { useEffect } from 'react'
import Layout from '../../hoc/Layout'
import { Link } from 'react-router-dom'
import { getSocialApi } from '../apiServices'
import { useDispatch, useSelector } from 'react-redux'
import { socialsuccess } from '../sharedSlice'

const Footer = () => {
    const { genericLoader } = useSelector(state => state.flightState)
    const { saveTravellerLoader } = useSelector(state => state.insuranceState)
    const { paymentWaitLoader } = useSelector(state => state.paymentState)
    const { buttonLoader } = useSelector(state => state.hotelState)
    const dispatch = useDispatch()
    useEffect(() => {
        const getTerms = async () => {
            const data = await getSocialApi();
            if (data) {
                dispatch(socialsuccess(data));
            }
        };
        getTerms();
    }, []);
    const terms = useSelector(
        (item) => item?.sharedState?.social?.information?.[0]
    );
    const facebooklink = terms?.facebook
    const instalink = terms?.instagram
    const twitter = terms?.twitter
    const linikedin = terms?.linkedIn
    const { translation } = useSelector((state) => state.sharedState)

    return (
        <div>
            {
                genericLoader || saveTravellerLoader || paymentWaitLoader || buttonLoader ?
                    null :
                    <Layout >
                        <div className='grid lg:grid-cols-10'>
                            <div className='grid grid-cols-2 gap-10 w-full lg:grid-cols-5  lg:col-span-10  items-baseline pt-[50px] '>
                                <div className='flex flex-col gap-3 cursor-pointer'>
                                    <div>
                                        <h3 className='text-lg font-bold text-black '>{translation?.explore}</h3>
                                    </div>
                                    <div className='flex flex-col gap-4 text-sm font-normal cursor-pointer text-neutral-400'>
                                        <a className='hover:text-emerald-800' href="/" ><h6>{translation?.home}</h6></a>
                                        <a className='hover:text-emerald-800' href='/?service=flight' >{translation?.flights}</a>
                                        <a className='hover:text-emerald-800' href='/?service=hotel'>{translation?.hotels}</a>
                                        <a className='hover:text-emerald-800' href="/packages"> {translation?.packages}</a>
                                        <a className='hover:text-emerald-800' href="/insurance">{translation?.travel_insurance}</a>
                                        <a className='hover:text-emerald-800' href="/activityCities" >{translation?.activities}</a>
                                    </div>

                                </div>
                                <div className='flex flex-col gap-3 cursor-pointer'>
                                    <div>
                                        <h3 className='text-lg font-bold text-black '>{translation?.about}</h3>
                                    </div>
                                    <div className='flex flex-col gap-4 text-sm font-normal cursor-pointer text-neutral-400'>
                                        <a className='hover:text-emerald-800' href="/general/aboutus"><h6>{translation?.about_us}</h6></a>
                                        <a className='hover:text-emerald-800' href="/general/terms-conditions"><h6>{translation?.terms_n_conditions}</h6></a>
                                        <a className='hover:text-emerald-800' href="/general/privacy-policy"><h6>{translation?.privacy_policy}</h6></a>
                                    </div>

                                </div>
                                <div className='flex flex-col gap-3 cursor-pointer'>
                                    <div>
                                        <h3 className='text-lg font-bold text-black'>{translation?.for_users}</h3>
                                    </div>
                                    <div className='flex flex-col gap-4 text-sm font-normal text-neutral-400'>
                                        <a className='hover:text-emerald-800' href="/login"><h6>{translation?.login}</h6></a>
                                        <a className='hover:text-emerald-800' href="/register"><h6>{translation?.register}</h6></a>
                                        <a className='hover:text-emerald-800' href='/general/settings'>{translation?.settings}</a>
                                    </div>

                                </div>
                                <div className='flex flex-col gap-3 cursor-pointer'>
                                    <div>
                                        <h3 className='text-lg font-bold text-black'>{translation?.follow_us}</h3>
                                    </div>
                                    <div className='flex gap-3'>
                                        <Link to={`${facebooklink}`} target='blank'><img className='w-5 h-5 sm:h-8 sm:w-8' src={require('../../assets/facebook3.png')} alt="" /></Link>
                                        <Link to={`${instalink}`} target='blank'>  <img className='w-5 h-5 sm:h-8 sm:w-8' src={require('../../assets/instagram3.png')} alt="" /></Link>
                                        <Link to={`${twitter}`} target='blank'><img className='w-5 h-5 sm:h-8 sm:w-8' src={require('../../assets/twitter.png')} alt="" /></Link>
                                        <Link to={`${linikedin}`} target='blank'><img className='w-5 h-5 sm:h-8 sm:w-8' src={require('../../assets/linkedin3.png')} alt="" /></Link>
                                        {/* <img className='w-5 h-5 sm:h-8 sm:w-8' src={require('../../assets/linkedin3.png')} alt="" /> */}
                                    </div>
                                    <div className='items-baseline justify-between hidden gap-5 mt-6 md:flex'>
                                        <div className='flex flex-col items-center justify-center gap-2'>
                                            <img src={require('../../assets/license/Emblem_of_Kuwait.png')} alt="" className='object-contain w-12 ' />
                                            <p className='text-sm text-font-gray'>2012/2323/M</p>
                                        </div>
                                        <div className='flex flex-col items-center justify-center gap-2'>
                                            <img src={require('../../assets/license/DGCA_Kuwait.png')} alt="" className='object-contain w-16 ' />
                                            <p className='text-sm text-font-gray'>13384/42</p>
                                        </div>
                                        <div className='flex flex-col items-center justify-center gap-2'>
                                            <img src={require('../../assets/license/iatalogo.png')} alt="" className='object-contain w-16 h-16' />
                                            <p className='text-sm text-font-gray'>42212332</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-baseline justify-between gap-5 mt-6 md:hidden'>
                                        <div className='flex flex-col items-center justify-center gap-2'>
                                            <img src={require('../../assets/license/Emblem_of_Kuwait.png')} alt="" className='object-contain w-12 ' />
                                            <p className='text-sm text-font-gray'>2012/2323/M</p>
                                        </div>
                                        <div className='flex flex-col items-center justify-center gap-2'>
                                            <img src={require('../../assets/license/DGCA_Kuwait.png')} alt="" className='object-contain w-16 ' />
                                            <p className='text-sm text-font-gray'>13384/42</p>
                                        </div>
                                        <div className='flex flex-col items-center justify-center gap-2'>
                                            <img src={require('../../assets/license/iatalogo.png')} alt="" className='object-contain w-16 h-16' />
                                            <p className='text-sm text-font-gray'>42212332</p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center gap-2 pt-8 pb-16 md:pb-24 lg:pb-8 md:flex-row '>
                            <h4 className='text-sm font-normal text-black sm:text-sm lg:text-base '>Copyright © {new Date().getFullYear()} Flytern, Inc.- All Rights Reserved.</h4>
                        </div>
                    </Layout>
            }
        </div>
    )
}

export default Footer
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { arabic_translation } from '../../../constants/index.js'
import TravelStoriesListHome from '../components/TravelStoriesListHome.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { getExploresApi } from '../apiServices.js'

const TravelStoriesHome = ({ type }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { selectedLanguageAndCountry } = useSelector(state => state.sharedState)
  useEffect(() => {
    dispatch(getExploresApi)
  }, [])
  const  { translation} = useSelector((state) =>  state.sharedState)
  return (

    <div className='mt-12 mb-8'>

      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-1 text-[10px] sm:text-sm font-normal text-neutral-400'>
          <h3 className='cursor-pointer' onClick={() => navigate('/')} >{translation?.home}</h3>
          <h3>/</h3>
          <h3 className='font-medium text-black'>{type === "general" ? '' : 'My'} {translation?.travel_stories}</h3>
        </div>
        <div className=''>
          <button className='text-orange-400 text-[11px] sm:text-[13px] font-medium  px-2.5 bg-white rounded-[5px] border border-orange-400 justify-center items-center h-10 sm:w-40' onClick={() => navigate('/profile/my-travel-stories/add-testimonial')}  >{selectedLanguageAndCountry?.language?.code === "ar" ? arabic_translation.add_testmonial : 'Add Testimonial'}</button>
        </div>
      </div>
      <div className="grid grid-cols-10 gap-8 mt-8">
        <div className='hidden col-span-2 lg:block '>
          {/* <SideBar sideBarList={type === "general" ? generalSidebarData : profileSidebarData} /> */}
        </div>
        <div className='col-span-10 lg:col-span-10'>
          <TravelStoriesListHome type={type} />
        </div>
      </div>
    </div>
  )
}

export default TravelStoriesHome
import { checkApiStatus } from '../../utils';
import { globalFileUploadPutService, globalGetService, globalPostService, globalFileUploadService, globalPatchService } from '../../utils/globalApiServices';
import { profileFail, loaderRequest, profileSuccess, coPaxSuccess, coPaxFail, myBookingsSuccess } from './profileSlice';
export const getProfileDetailApi = async (dispatch) => {
  try {
    dispatch(loaderRequest());
    const data = await globalGetService('/api/Users/GetUserProfile')
      .then((response) => {
        return response.data.data;
      });

    dispatch(profileSuccess(data));
  } catch (error) {
    dispatch(profileFail(error.response.data.message))
  }
};


export const getCoPaxApi = async (dispatch) => {
  try {
    dispatch(loaderRequest());
    const data = await globalGetService('api/CoPaxs/GetUserCoPaxs')
      .then((response) => {
        return response.data.data;
      });
    dispatch(coPaxSuccess(data));
  } catch (error) {
    dispatch(coPaxFail(error.response.data.message))
  }
}

export const getMyBookingsApi = (pageId, serviceType) => {
  return async (dispatch) => {
    try {
      dispatch(loaderRequest());
      const data = await globalGetService(`/api/Bookings/GetMyBooking?pageid=${pageId}&servicetype=${serviceType}`)
        .then((response) => {
          return response.data.data
        });
      dispatch(myBookingsSuccess(data))
    } catch (error) {
    }
  }
}

export const updateProfileApi = (data) => {
  return globalFileUploadPutService('/api/Users/UpdateUserProfile', data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export const updateMobileApi = (data) => {
  return globalPostService('/api/Users/ChangeMobile', data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    })
}

export const updateEmailApi = (data) => {
  return globalPostService('/api/Users/ChangeEmail', data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    })
}

export const updateOtpApi = (data) => {
  return globalPostService('/api/Users/VerifyOTP', data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error
    })
}

export const addTestimonialApi = (data) => {
  return new Promise((resolve, reject) => {
    globalFileUploadService('/api/TravelStories/CreateTestimonials', data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const getMyTravelStoriesApi = (setData, setLoader) => {
  setLoader(true);
  return globalGetService('/api/TravelStories/GetTravelStories')
    .then((response) => {
      setData(response.data.data);
      setLoader(false);
    })
    .catch((error) => {
      return error;
    });
}
export const addCoPaxApi = (data) => {
  return new Promise((resolve, reject) => {
    globalPostService(`/api/CoPaxs/CreateUserCopax`, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const updateCoPaxApi = (data) => {
  return new Promise((resolve, reject) => {
    globalPostService(`/api/CoPaxs/UpdateUserCopax`, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const updatePasswordApi = async (data) => {
  try {
    const res = await globalPatchService(`/api/Users/ChangePassword`, data)
    const datas = await checkApiStatus(res)
    return datas
  } catch (error) {
    return error
  }

}

export const viewCoPaxApi = (id, setData, setLoader) => {
  setLoader(true)
  globalGetService(`/api/CoPaxs/ViewUserCoPax/${id}`)
    .then(response => {
      if (checkApiStatus(response)) {
        setData({ ...response.data.data, id: parseInt(id) })
      }
      setLoader(false)
    })
    .catch(error => {

    })
}

export const deleteCopaxApi = async (id) => {

  try {
    const data = await globalPostService(`/api/CoPaxs/DeleteUserCopax/${id}`)
    const res = await checkApiStatus(data)
  } catch (error) {
    return error
  }

}


export const getGuestBookingsApi = async (payload, navigate) => {
  try {
    const response = await globalPostService(`/api/Payments/ViewBooking`, payload)
    const responseStatus = await checkApiStatus(response)
    if (responseStatus) {
      navigate(`/payment-summary/${response.data.data.bookingRef}`)
    }
  }
  catch (error) {
    return error
  }
}
import React from "react";
import flights from "../../assets/icons/flight.svg";
import hotels from "../../assets/icons/Buildings.svg";
import packages from "../../assets/icons/Suitcase Tag.svg";
import travelInsurance from "../../assets/icons/Heart Pulse.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const SubHeader = ({ lang }) => {
  const navigate = useNavigate();
  const { translation } = useSelector((state) => state.sharedState)
  return (
    <div className="w-full h-[55px] bg-emerald-800 shadow border-b border-neutral-200 text-white">
      <div className="container flex items-center justify-between h-full px-4 mx-auto">
        <div className="flex items-center justify-between gap-10">
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => lang ? navigate(`/${lang}/flights`) : navigate("/flights")}
          >
            <img src={flights} alt="flight" className=" flight" />
            <p className="">
              {translation?.flights}
            </p>
          </div>
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => lang ? navigate(`/${lang}/hotels`) : navigate("/hotels")}
          >
            <img src={hotels} alt="flight" className=" hotel" />
            <p className="">
              {translation?.hotels}
            </p>
          </div>
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => lang ? navigate(`/${lang}/packages`) : navigate("/packages")}
          >
            <img src={packages} alt="flight" className="packages" />
            <p className="">
              {translation?.packages}
            </p>
          </div>
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => lang ? navigate(`/${lang}/insurance`) : navigate("/insurance")}
          >
            <img src={travelInsurance} alt="flight" className="insurance" />
            <p className="cursor-pointer">
              {translation?.travel_insurance}
            </p>
          </div>
        </div>
        <div className="relative h-10">

        </div>
      </div>
    </div>
  );
};

export default SubHeader;

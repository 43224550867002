import React, { useEffect, useState } from 'react'
import TitleCard from '../../../shared/components/TitleCard'
import { useDispatch, useSelector } from 'react-redux'
import { getMyTravelStoriesApi } from '../../profile/apiServices'
import {  displayDateFormatShort } from '../../../constants'
import moment from 'moment/moment'
import { SuspenseLoader } from '../../../shared'
import { Rating } from '@mui/material'
import ReactPlayer from 'react-player'
const TravelStoriesListHome = ({ type }) => {
    const { data: { travelStories = {} } } = useSelector((state) => state.exploresState);
  
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
      if (type === "profile") {
        getMyTravelStoriesApi(setData, setLoader)
      } else {
        setData(travelStories);
        setLoader(false);
      }
  
    }, [dispatch, type, travelStories])
    const  { translation} = useSelector((state) =>  state.sharedState)
    return (
      <>
        {loader ?
          <SuspenseLoader /> :
          <TitleCard
            topMargin='0'
            title={
              <>
                <div className='flex items-center justify-between'>
                  <h4>{translation?.travel_stories}</h4>
                </div>
              </>
            } >
            <div className='grid gap-10 mt-5 sm:grid-cols-2'>
              {
                data && data?.map((story, index) => {
                  return (
                    <div className='flex flex-col justify-start gap-3' key={index}>
                      {type === "profile" ? (
                        <img src={story?.fileUrl} alt='' className='w-full rounded-md' />
                      ) : story.urlType === 'VIDEO' ? (
                        <ReactPlayer
                          url={story.url}
                          width="100%"
                          height="100%"
                          controls
                        />
                      ) : (
                        <img src={story?.url} alt='' className='object-cover w-full h-40 rounded-md sm:h-48 md:h-full' />
                      )}
                      <div className='flex flex-col gap-5'>
                        <div className='flex flex-col gap-2 sm:flex-row sm:items-center'>
                          <div className='flex items-center gap-3'>
                            <img src={story?.profileUrl} alt='' className='w-6 h-6 rounded-full' />
                            <p class="text-black text-xs font-medium">{story?.firstName}</p>
                          </div>
                          <div>
                            <p class="text-stone-500 text-xs font-normal tracking-tight">{moment(story?.createdon).format(displayDateFormatShort)} </p>
                          </div>
                        </div>
                        <div className='flex flex-col gap-3'>
  
                          {type === "profile" ? (
  
                            <Rating name="read-only"
                              value={story?.rating}
                              readOnly
                              precision={0.5}
                            />
                          ) : (
                            <Rating name="read-only"
                              value={story?.ratings}
                              readOnly
                              precision={0.5}
                            />
                          )
                          }
                          <h4 className='text-sm font-medium text-black '>{story?.title}</h4>
                          <p class=" text-stone-500 text-[13px] font-normal leading-[200%] tracking-tight">{story?.tripSummary}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </TitleCard>
        }
  
      </>
  
    )
  }

export default TravelStoriesListHome
import { lazy } from "react";
import Layout from "../../hoc/Layout";
import HeaderLayout from "../../hoc/HeaderLayout";
import AboutUs from "./containers/AboutUs";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import HelpCenter from "./containers/HelpCenter";
import ContactUs from "./containers/ContactUs";

const TermsAndCondition = lazy(() => import("../general/containers/TermsAndConditions"))
const TravelStories = lazy(() => import("../../shared/containers/TravelStories"))
const ManageBooking = lazy(() => import("./containers/ManageBooking"))
const SmartPayment = lazy(() => import("./containers/SmartPayment"))
const SmartPaymentFlight = lazy(() => import("./containers/SmartPaymentFlight"))
const Recomended = lazy(() => import("./containers/RecomendedMain"))
const PopularDestination = lazy(() => import('./containers/PopularDestinationMain'))
const Settings = lazy(() => import('./containers/Settings'))

export const generalRoutes = [
  {
    path: "/general/travel-stories",
    exact: true,
    component: <HeaderLayout><Layout><TravelStories type="general" /></Layout></HeaderLayout>
  },
  {
    path: "/general/recomended",
    exact: true,
    component: <HeaderLayout><Layout><Recomended type="general" /></Layout></HeaderLayout>
  },
  {
    path: "/general/popular-destination",
    exact: true,
    component: <HeaderLayout><Layout><PopularDestination type="general" /></Layout></HeaderLayout>
  },
  {
    path: "/general/manage-booking",
    exact: true,
    component: <HeaderLayout><Layout><ManageBooking /></Layout></HeaderLayout>
  },
  {
    path: "/general/smart-payment",
    exact: true,
    component: <HeaderLayout><Layout><SmartPayment type="general" /></Layout></HeaderLayout>
  },
  {
    path: "/general/smart-payment/:bookingRef",
    exact: true,
    component: <HeaderLayout><Layout><SmartPaymentFlight type="general" /></Layout></HeaderLayout>
  },
  {
    path: "/general/terms-conditions",
    exact: true,
    component: <HeaderLayout> <Layout><TermsAndCondition /></Layout></HeaderLayout>
  },
  {
    path: "/general/aboutus",
    exact: true,
    component: <HeaderLayout><Layout><AboutUs /></Layout></HeaderLayout>
  },
  {
    path: "/general/privacy-policy",
    exact: true,
    component: <HeaderLayout><Layout><PrivacyPolicy /></Layout></HeaderLayout>
  },
  {
    path: "/general/settings",
    exact: true,
    component: <HeaderLayout><Layout><Settings /></Layout> </HeaderLayout>
  },
  {
    path: "/general/help-center",
    exact: true,
    component: <HeaderLayout><Layout><HelpCenter type="general" /></Layout> </HeaderLayout>
  },
  {
    path: "/general/contact-us",
    exact: true,
    component: <HeaderLayout><Layout><ContactUs /></Layout> </HeaderLayout>
  }
];
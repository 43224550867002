import React from 'react'
import { useNavigate } from 'react-router-dom';

import { NavigatorActivitySvg, NavigatorFlightSvg, NavigatorHotelSvg, NavigatorInsuranceSvg, NavigatorPackageSvg, NavigatorProfileSvg } from '../../../assets/svg';
import { useLocation } from 'react-router-dom';

const MobileNavigator = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const isHotelPage = location.pathname === '/' && location.search === '?service=hotel';
  const isFlightPage = location.pathname === '/' && location.search === '?service=flight';
  

  return (
    <div className="w-full h-[55px] md:h-[90px] bg-emerald-800 shadow border-b border-neutral-200 text-white  bottom-0 z-10 fixed">
      <div className="flex items-center justify-around h-full ">
        <div className="flex items-center justify-between gap-10 md:gap-28 w-full px-5">
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate('/?service=flight')}
          >
            <NavigatorFlightSvg color={isFlightPage ? "#FFA726" : '#fff'}/>
          </div>
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate('/?service=hotel')}
          >
            <NavigatorHotelSvg color={isHotelPage ? "#FFA726" : '#fff'} />
          </div>
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate("/packages")}
          >
            <NavigatorPackageSvg color={location.pathname.includes('/packages') ? "#FFA726" : '#fff'}/>
          </div>
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate("/insurance")}
          >
            <NavigatorInsuranceSvg color={location.pathname.includes('/insurance') ? "#FFA726" : '#fff'}/>
          </div>
          {/* <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate("/activityCities")}
          >
            <NavigatorActivitySvg color={location.pathname.includes('/activityCities') ? "#FFA726" : '#fff'}/>
          </div> */}
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate(`/profile`)}
          >
            <NavigatorProfileSvg color={location.pathname.includes('/profile') ? "#FFA726" : '#fff'} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNavigator
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './utils/store';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { getGlobalCookie } from './utils';

const language = getGlobalCookie('language');

// Conditionally add rtlPlugin to stylisPlugins based on the language
const stylisPlugins = language === 'ar' ? [prefixer, rtlPlugin] : [prefixer];

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: stylisPlugins,
});

const theme = createTheme({
  direction: language === 'ar' ? 'rtl' : 'ltr',
  typography : {
    fontFamily: language === 'ar' ? 'arabic' : 'inter,sans-serif',
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
    <Provider store={store}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  </SnackbarProvider>
);

import { lazy } from "react";
import Layout from "../../hoc/Layout";
import HeaderLayout from "../../hoc/HeaderLayout";
const HotelSearch = lazy(() => import("./containers/HotelSearch"))
const HotelDetails = lazy(() => import("./containers/HotelDetails"))


export const hotelRoutes = [
  {
    path: "/hotel/search",
    exact: true,
    component: <HeaderLayout><Layout > < HotelSearch /> </Layout></HeaderLayout>
  },
  {
    path: "/ar/hotel/search",
    exact: true,
    component: <HeaderLayout lang="ar" ><Layout > <HotelSearch lang="ar" /> </Layout></HeaderLayout>
  },
  {
    path: "/hotel/details/:objectID/:id",
    exact: true,
    component: <HeaderLayout lang="ar"><Layout > <HotelDetails /> </Layout></HeaderLayout>
  },
  {
    path: "/ar/hotel/details/:objectID/:id",
    exact: true,
    component: <HeaderLayout lang="ar"><Layout > <HotelDetails lang="ar" /> </Layout></HeaderLayout>
  }
]
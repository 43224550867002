import { lazy } from "react";
import Layout from "../../hoc/Layout";
import HeaderLayout from "../../hoc/HeaderLayout";
const Profile = lazy(() => import("./containers/Profile"))
const EditProfile = lazy(() => import('./containers/EditProfile'))
const CoPaxList = lazy(() => import('./containers/CoPaxList'))
const AddCoPax = lazy(() => import('./containers/AddCoPax'))
const EditCoPax = lazy(() => import('./containers/CopaxEdit'))
const MyBookings = lazy(() => import('./containers/Mybookings'));
const ChangePassword = lazy(() => import('./containers/ChangePassword'));
const MyFlightDetails = lazy(() => import('./containers/MyFlightDetails'));
const Refund = lazy(() => import('./containers/Refund'));
const TravelStories = lazy(() => import('../../shared/containers/TravelStories'));
const AddTestimonial = lazy(() => import('../../shared/containers/AddTestimonial'));





export const profileRoutes = [
  {
    path: "/profile",
    exact: true,
    component: <HeaderLayout><Layout><Profile /></Layout></HeaderLayout>
  },
  {
    path: "/profile/edit",
    exact: true,
    component: <HeaderLayout><Layout><EditProfile /></Layout></HeaderLayout>
  },
  {
    path: "/profile/co-pax",
    exact: true,
    component: <HeaderLayout><Layout><CoPaxList /></Layout></HeaderLayout>
  },
  {
    path: "/profile/co-pax/add",
    exact: true,
    component: <HeaderLayout><Layout><AddCoPax /></Layout></HeaderLayout>
  },
  {
    path: "co-pax/edit/:id",
    exact: true,
    component: <HeaderLayout><Layout><EditCoPax /></Layout></HeaderLayout>
  },
  {
    path: '/profile/my-bookings',
    exact: true,
    component: <HeaderLayout><Layout><MyBookings /></Layout></HeaderLayout>
  },
  {
    path: '/profile/my-travel-stories',
    exact: true,
    component: <HeaderLayout><Layout><TravelStories type="profile" /></Layout></HeaderLayout>
  },
  {
    path: '/profile/my-travel-stories/add-testimonial',
    exact: true,
    component: <HeaderLayout><Layout><AddTestimonial type="profile" /></Layout></HeaderLayout>
  },
  {
    path: '/profile/change-password',
    exact: true,
    component: <HeaderLayout><Layout><ChangePassword type="profile" /></Layout></HeaderLayout>
  },
  {
    path: '/profile/my-bookings/flight',
    exact: true,
    component: <HeaderLayout><Layout><MyFlightDetails type="profile" /></Layout></HeaderLayout>
  },
  {
    path: '/profile/flight/refund',
    exact: true,
    component: <HeaderLayout><Layout><Refund type="profile" /></Layout></HeaderLayout>
  }
];
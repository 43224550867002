
import { loaderOff } from "../../shared/sharedSlice"
import { checkApiStatus } from "../../utils"
import { globalGetService, globalPostService } from "../../utils/globalApiServices"
import { initialInfoSucces, loadingOff, loadingOn, popularDestinationSuccess, recomendedSuccess, travelStoriesSuccess } from "./genaralSlice"


export const getRecomendedApi = (pageId) => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService(`/api/Explores/GetViewAllRecommeded?pageid=${pageId}`)
        .then((response) => {
          dispatch(loadingOff())
          return response.data.data
        })
      dispatch(recomendedSuccess(data))
    } catch (error) {
    }
  }
}

export const getIntialInfoApi = async (dispatch) => {
  try {
    dispatch(loadingOn())
    const data = await globalGetService(`/api/Supports/GetInitalInfo`)
      .then((response) => {
        dispatch(loadingOff())
        return response.data.data
      })
    dispatch(initialInfoSucces(data))
  } catch (error) {
  }
}

export const travelStoriesApi = (setData, setLoader, pageId) => {
  setLoader(true);
  return globalGetService(`/api/Explores/GetViewAllTravelStories?pageid=${pageId}`)
    .then((response) => {
      setData(response.data.data.records);
      setLoader(false);
    })
    .catch((error) => {
      return error;
    });
}

export const popularDestinationApi = (pageId) => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService(`/api/Explores/GetViewAllpopularDestinations?pageid=${pageId}`)
        .then((response) => {
          dispatch(loadingOff())
          return response.data.data
        })
      dispatch(popularDestinationSuccess(data))
    } catch (error) {
    }
  }
}

export const postQueryApi = async (payload) => {
  try {
    const response = await globalPostService('/api/Bookings/Submitinquiry', payload)
    return response
  } catch (error) {
    return error
  }
}


export const postSmartPaymentApi = async (payload) => {
  try {
    const response = await globalPostService(`/api/Payments/SmartPayment`, payload)

    return response
  } catch (error) {
    return error
  }
};

export const postSmartPaymentGatewayApi = async (payload) => {

  try {
    const response = await globalPostService(`/api/Payments/SaveGateway`, payload)
    return response
  }
  catch (error) {
    return error
  };
};

export const saveDeviceLanguage = async (payload) => {
  try {
    const response = await globalPostService(`api/Supports/SaveDeviceLang`, payload)
    return response
  } catch (error) {
    return error
  }
}

export const getTermsApi = async () => {

  try {
    const data = await globalGetService(`/api/Supports/Info?type=TERMS`)
    if (checkApiStatus(data)) {
      return data.data.data
    }
  }

  catch (error) {
    return error
  };

};

export const getAboutApi = async () => {

  try {
    const data = await globalGetService(`/api/Supports/Info?type=ABOUTUS`)
    if (checkApiStatus(data)) {
      return data.data.data
    }
  }

  catch (error) {
    return error
  };

};

export const getPrivacyApi = async () => {
  try {
    const data = await globalGetService(`/api/Supports/Info?type=PRIVACY`)
    if (checkApiStatus(data)) {
      return data.data.data
    }
  }

  catch (error) {
    return error
  };

};

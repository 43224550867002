import React, { useEffect } from 'react'
import FlightBookingCard from './FlightBookingCard';
import Carousal from './Carousal';
import HotelBookingCard from './HotelBookingCard';


const Hero = ({ selectedCard, backgroundImage, lang }) => {


  useEffect(() => {
    document.getElementById('hero').style.backgroundImage = `url(${backgroundImage})`
    document.getElementById('hero').style.backgroundSize = 'cover'
    document.getElementById('hero').style.backgroundRepeat = 'no-repeat'
    document.getElementById('hero').style.backgroundPosition = 'center'
  }, [selectedCard, backgroundImage])

  return (
    <div className='flex flex-col items-center justify-baseline w-full md:min-h-[600px] pb-4 h-full duration-300  bg-cover bg-no-repeat ease-in-out' id="hero"  >
      <div className='container grid gap-10 px-4 mx-auto mt-8 lg:grid-cols-3 sm:mt-24' >
        <div className='flex flex-col justify-start col-span-2 gap-10' >
          {/* <h4 className="hidden text-2xl font-bold text-white cursor-pointer sm:block">{language === "ar" ? arabic_translation.quick_booking:"Quick Booking"}</h4> */}
          {selectedCard === "flight" && <FlightBookingCard lang={lang}  />}
          {selectedCard === "hotel" && <HotelBookingCard lang={lang} />}
        </div>
        <div className='hidden w-full col-span-1 lg:block xl:w-[330px]'>
          <Carousal />
        </div>
      </div>
    </div>
  )
}

export default Hero
import { set } from "date-fns";
import { checkApiStatus } from "../../utils";
import { globalGetService } from "../../utils/globalApiServices"
import { exploresFail, exploresRequest, exploresSuccess, setHotelQuickSearch } from "./exploresSlice"

export const getExploresApi = async (dispatch) => {
  try {
    dispatch(exploresRequest());
    const data = await globalGetService('/api/Explores/GetExplores')
      .then((response) => {
        return response.data.data;
      });
    dispatch(exploresSuccess(data));
  } catch (error) {
    dispatch(exploresFail(error.response.data.message))
  }
}

export const getDestinationAutoSearchApi = (search, setSearchResult, setDestinationLoader) => {
  if (search.length) {
    globalGetService('/api/Flights/GetDestination', { search })
      .then(response => {
        if (checkApiStatus(response)) {
          setSearchResult(response.data.data)
        } else {
          setSearchResult([])
        }
      })
  } else {
    setSearchResult([])
  }
}

export const getHotelDestinationAutoSearchApi = (search, setSearchResult, setDestinationLoader) => {
  setDestinationLoader(true)
  if (search.length < 3) return setSearchResult([])
  globalGetService('/api/Hotels/GetDestination', { search })
    .then(response => {
      setDestinationLoader(false)
      if (checkApiStatus(response)) {
        setSearchResult(response.data.data)
      } else {
        setSearchResult([])
      }
    })
}

export const getHotelQuickSearchApi = async (dispatch) => {
  try {
    const data = await globalGetService('/api/Hotels/GetQuickSearch')
      .then((response) => {
        if (checkApiStatus(response)) {
          return response.data.data;
        }
      });
    dispatch(setHotelQuickSearch(data))
  }
  catch (error) {
    return error
  }
}   
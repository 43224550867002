import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Carousal = () => {
  const  { data : { apiSlider = []} } = useSelector(state => state.exploresState)
  const navigate = useNavigate()
  console.log(apiSlider, 'apiSlider')
  return (
    <Swiper
      // spaceBetween={50}
      slidesPerView={1}
      modules={[Autoplay, Pagination]}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
        bulletClass: 'swiper-pagination-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active',
      }}
      className='w-full'
      onSwiper={(swiper) => {}}
    > 
    {
      apiSlider?.map((item, index) => (
        <SwiperSlide key={index} className='cursor-pointer'>
          <img src={item?.url} alt="" className='rounded-md' onClick={() =>  navigate(`/packages/details/${item.refID}`)} />
        </SwiperSlide>
      ))
    }
    </Swiper>
  );
};

export default Carousal;

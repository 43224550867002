import { lazy } from "react";

const Login = lazy(() => import("./containers/Login"));
const Register = lazy(() => import("./containers/Register"));
const ForgotPassword = lazy(() => import("./containers/ForgotPassword"));
const Otp = lazy(() => import("./containers/Otp"));
const Reset = lazy(() => import("./containers/Reset"));


export const authRoutes = [
  {
    path: "/login",
    component: <Login />,
    exact: true,
  },
  {
    path: "/register",
    component: <Register />,
    exact: true,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
    exact: true,
  },
  {
    path: "/otp",
    component: <Otp />,
    exact: true,
  },
  {
    path: "/reset-password",
    component: <Reset />,
    exact: true,
  }
]
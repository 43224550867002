import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activityLoader:false,
  activityCitiesDestinations: undefined,
  activities: undefined,
  activityDetails: undefined,
  pretraveller: {},
  saveTraveller: {
    _eventlstInfo: [],
    _leadInfo: {},
  }
};

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    activityLoaderOn(state,action){
      return{
        ...state,
        activityLoader:true
      }
    },
    activityLoaderOff(state,action){
      return{
        ...state,
        activityLoader:false
      }
    },
    setActivitiesCities(state, action) {
      return {
        ...state,
        activityCitiesDestinations: action.payload,
      };
    },
    setActivities(state, action) {
      return {
        ...state,
        activities: action.payload,
      };
    },
    setActivityDetails(state, action) {
      return {
        ...state,
        activityDetails: action.payload,
      };
    },
    setPreTraveler(state, action) {
      return {
        ...state,
        pretraveller: action.payload,
      };
    },
    setSaveTraveller(state, action) {
      return {
        ...state,
        saveTraveller: action.payload,
      };
    },
  },
});
const { reducer, actions } = activitySlice;

export const { setActivitiesCities, setActivities, setActivityDetails,activityLoaderOff,activityLoaderOn, setPreTraveler, setSaveTraveller } = actions;

export default reducer;

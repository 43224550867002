import destination1 from '../assets/destination_1.png'
import destination2 from '../assets/destination_2.png'
import destination3 from '../assets/destination_3.png'
import travel1 from '../assets/travel_1.png'
import travel2 from '../assets/travel_2.png'
import travel3 from '../assets/travel3.png'
import travel4 from '../assets/travel4.png'
import author1 from '../assets/author_1.png'
import author2 from '../assets/author_2.png'
import rough from '../assets/rough.png';
import oxcado from '../assets/oxcado.png';
import istanbul from '../assets/isthambul.png';
import kabul from '../assets/kabul.png';
import ocean from '../assets/ocean.png';
import activityone from '../assets/activity1.png';
import activitytwo from '../assets/activity2.png';
import activitythree from '../assets/activity3.png';
import activityfour from '../assets/activity4.png';
import visa from '../assets/icons/visa.svg';
import applePay from '../assets/icons/apple_pay.svg';
import paypal from '../assets/icons/paypal.svg';
import wifi from '../assets/ameneties/wifi.svg';
import roomService from '../assets/ameneties/room_service.svg';
import meal from '../assets/ameneties/meal.svg';
import freeParking from '../assets/ameneties/free_parking.svg';
import airportShuttle from '../assets/ameneties/airport_shuttle.svg';
import { BookingIconSvg, ChangePasswordIconSvg, FaqIconSvg, SettingsIconSvg, TravelAssistanceIconSvg, TravelStoriesIconSvg } from '../assets/svg';
import { getGlobalCookie } from '../utils'
export const dateFormat = 'dd-MM-yyyy'
export const displayDateFormatShort = "MMM DD, YYYY";
export const displayDateFormat = "MMM DD, YYYY hh:mm A";
export const displayWeekDateFormat = "dddd, MMMM Do YYYY, h:mm a";
export const dateQueryFormat = "MMM DD YYYY hh:mmA";
export const fieldDateFormat = 'DD-MM-YYYY';
export const backendDateFormat = "dd-MM-yyyy"

const language = getGlobalCookie('language')



export const imagesData = [
  {
    image: destination1,
    title: "Four Seasons Resort Bora",
    rating: 4.5
  },
  {
    image: destination2,
    title: "Burj Khalifa",
    rating: 4.9
  },
  {
    image: destination3,
    title: "The Royal Oasis",
    rating: 4.3
  }, {
    image: destination3,
    title: "The Royal Oasis",
    rating: 4.3
  }
]

export const travelStories = [
  {
    image: travel1,
    author_img: author1,
    author: 'Easton Rodricks',
    posted_at: 'January 24, 2023 1:17 PM',
    rating: 4,
    content: 'It was a great experience to travel with Air Canada, the flight was comfortable and the crew was very humble.'
  },
  {
    image: travel2,
    author_img: author2,
    author: 'Robert Fox',
    posted_at: 'January 24, 2023 1:17 PM ',
    rating: 4,
    content: 'I will undoubtedly choose them again for my future travels. Kudos to the entire airline team for their dedication and commitment to ensuring a remarkable passenger experience!'
  },
  {
    image: travel3,
    author_img: author2,
    author: 'Robert Fox',
    posted_at: 'January 24, 2023 1:17 PM ',
    rating: 4,
    content: 'I will undoubtedly choose them again for my future travels. Kudos to the entire airline team for their dedication and commitment to ensuring a remarkable passenger experience!'
  },
  {
    image: travel4,
    author_img: author2,
    author: 'Robert Fox',
    posted_at: 'January 24, 2023 1:17 PM ',
    rating: 4,
    content: 'I will undoubtedly choose them again for my future travels. Kudos to the entire airline team for their dedication and commitment to ensuring a remarkable passenger experience!'
  },
  {
    image: travel1,
    author_img: author2,
    author: 'Robert Fox',
    posted_at: 'January 24, 2023 1:17 PM ',
    rating: 4,
    content: 'I will undoubtedly choose them again for my future travels. Kudos to the entire airline team for their dedication and commitment to ensuring a remarkable passenger experience!'
  },
  {
    image: travel2,
    author_img: author2,
    author: 'Robert Fox',
    posted_at: 'January 24, 2023 1:17 PM ',
    rating: 3,
    content: 'I will undoubtedly choose them again for my future travels. Kudos to the entire airline team for their dedication and commitment to ensuring a remarkable passenger experience!'
  }
]

export const packageData = [
  {
    image: rough,
    title: 'Kabul Holiday Package',
    price: 12000
  },
  {
    image: oxcado,
    title: 'Oaxaca Holiday Package',
    price: 12000
  },
  {
    image: istanbul,
    title: 'Istanbul Holiday Package',
    price: 18000
  },
  {
    image: kabul,
    title: 'Kabul Holiday Package',
    price: 18000
  },
  {
    image: ocean,
    title: 'Oaxaca Holiday Package',
    price: 12000
  },


]




export const paymentTypesBreadCumb = {
  'flight': 'Flight Details',
  'hotel': 'Hotel Details',
  'package': 'Package Details',
  'activities': 'Activities Details',
  'insurance': 'Insurance Details',
}

export const paymentMethodTypes = [
  {
    name: 'Apple Pay',
    logo: applePay,
  }
  ,
  {
    name: 'Paypal',
    logo: paypal,
  },
  {
    name: 'Visa',
    logo: visa,
  }
]


export const amenities = [
  {
    image: wifi,
    title: 'Free Wifi'
  },
  {
    image: roomService,
    title: 'Room Service'
  },
  {
    image: meal,
    title: 'Meal'
  },
  {
    image: freeParking,
    title: 'Free Parking'
  },
  {
    image: airportShuttle,
    title: 'Airport Shuttle'
  }
]

export const arabic_translation = {
  package_details: 'حزمة من التفاصيل',
  checkout_date: 'موعد انتهاء الأقامة',
  checkin_date: 'تاريخ الوصول',
  flight_date: 'تاريخ الرحلة',
  follow_us: 'تابعنا',
  for_free: 'مجانًا وابدأ في استخدامه اليوم!',
  informed_offer: 'كن على علم بالعروض الخاصة.',
  downld_app: 'قم بتنزيل تطبيقنا!',
  powered_by: 'مشغل بواسطة',
  design: 'تصميم بواسطة',
  copy: 'حقوق الطبع والنشر © 2023 Flytern, Inc.- جميع الحقوق محفوظة.',
  register: 'يسجل',
  about_us: 'معلومات عنا',
  about: 'عن',
  explore: 'يستكشف',
  policy_relationship: 'علاقة السياسة',
  policy_nationality: 'جنسية السياسة',
  social_account: 'الحساب الاجتماعي',
  travel_assistance: 'مساعد السفر',
  login: 'تسجيل الدخول',
  form_note: 'ملاحظة: سوف تتلقى التحديثات على تفاصيل الاتصال المذكورة أعلاه.',
  cabin_class: 'فئة المقصورة',
  twnty: '20 كجم',
  economy: 'اقتصاد',
  flight_no: 'رقم الرحلة',
  fare_rule: 'حكم الأجرة',
  other: 'آخر',
  stops: 'توقف',
  arrival_time: 'وقت الوصول',
  departure_time: 'وقت المغادرة',
  airline: 'شركة طيران',
  price: 'سعر',
  filter_by: 'مصنف بواسطة',
  economic_budget: 'ميزانية الاقتصاد',
  Cancellation: 'إلغاء',
  e_ticket: 'احصل على تذكرتك الإلكترونية',
  setting: 'إعدادات',
  traveler: 'مسافر',
  rooms: 'غرفتان',
  rixo_prem: 'ريكسوس بريميوم دبي جي بي آر',
  your_booking: 'حجزك',
  pay_now: 'ادفع الآن',
  contact_note: 'ملاحظة: سوف تتلقى التحديثات على تفاصيل الاتصال المذكورة أعلاه',
  co_pax: 'شارك في باكس',
  baggage_note: 'ملحوظة: قطعة واحدة من الأمتعة (لا يزيد وزنها عن 20 كجم) لكل من البالغين والأطفال',
  baggage_content: 'قطعة واحدة من الأمتعة (لا يزيد وزنها عن 20 كجم) لكل من البالغين والأطفال',
  size: 'مقاس',
  economic_baggage: 'ميزانية الاقتصاد',
  search_results: 'نتائج البحث',
  flight_booking: 'حجز رحلة طيران',
  additional_service: 'خدمات إضافية',
  add_ons_service: 'خدمات الإضافات',
  silver: 'فضة',
  platinum: 'البلاتين',
  gold: 'ذهب',
  policy_plan: 'خطة السياسة',
  family_members: 'أفراد الأسرة',
  family: 'عائلة',
  individual: 'فردي',
  policy_type: 'نوع السياسة',
  flight_insurance: 'تأمين الطيران',
  policy_time: 'وقت السياسة',
  hotel_booking: 'حجز الفندق',
  select_baggage: 'حدد الأمتعة',
  available_baggage: 'الأمتعة المتاحة',
  select_meal: 'اختر وجبة',
  available_meal: 'الوجبة المتوفرة',
  add: 'يضيف',
  economy_class: 'الدرجة السياحية',
  available: 'متاح',
  business_class: 'درجة الأعمال',
  passangers: 'ركاب',
  route: 'طريق',
  available_seats: 'المقاعد المتاحة',
  add_ons: 'الإضافات',
  enter_prefix: 'أدخل البادئة',
  continue: 'يكمل',
  enter_id: 'أدخل معرف',
  enter_passport_no: 'أدخل رقم جواز السفر',
  enter_frequent_flyer: 'أدخل المسافر الدائم',
  enter_country: 'أدخل البلد',
  enter_nationality: 'أدخل الجنسية',
  enter_first_name: 'أدخل الاسم الأول',
  enter_last_name: 'إدخال اسم آخر',
  enter_phone_number: 'أدخل رقم الهاتف',
  enter_email_address: 'أدخل عنوان البريد الالكتروني',
  review_details: 'تفاصيل المراجعة',
  phone_number: 'رقم التليفون',
  email_address: 'عنوان البريد الإلكتروني',
  full_name: 'الاسم الكامل',
  contact_details: 'بيانات المتصل',
  baggage: 'أمتعة',
  flight_rule: 'قاعدة الطيران',
  price_details: 'تفاصيل الأسعار',
  flight_summary: 'ملخص الرحلة',
  flight_details: 'تفاصيل الرحلة',
  continue_as: "الاستمرار كما",
  guest_user: "حساب زائر",
  select: 'يختار',
  refundable: ' مستردة',
  non_refundable: 'غير قابل للاسترجاع',
  sign_in: "تسجيل الدخول",
  create_account: "تسجيل جديد",
  or: 'أم',
  quick_booking: 'الحجز السريع',
  departure_date: 'تاريخ المغادرة',
  please_enter_otp: 'رجاء إدخال كلمة المرور لمرة واحدة (OTP) التي تلقيتها',
  flyturn_video_head_one: 'اكتشف، خطط،',
  flyturn_video_head_two: 'واكتشف مع',
  flyturn_video_head_three: 'يُسَهّل!',
  flyturn_video_content: 'أطلق العنان لحب التجوال من خلال تطبيق مخطط الرحلات البديهي. قم بتنظيم الرحلات واكتشف الجواهر المخفية واصنع ذكريات دائمة دون عناء.',
  welcome_flyturn: 'مرحبا بكم في فلايتيرن',
  login_head_content: 'وابتك للمغامرة. تسجيل الدخول واستكشاف!',
  send_verification: 'إرسال رمز التحقق',
  flytern_reset_content: 'يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمات المرور المستخدمة سابقًا',
  register_view_content: 'انضم إلينا ودعنا نحقق ذلك! سنتعامل مع التفاصيل بينما تستمتع بالمغامرة',
  user_details: 'بيانات المستخدم',
  Contributor: 'مساهم',
  enter_perfix: 'أدخل البادئة',
  note: 'ملاحظة: سوف تتلقى التحديثات على تفاصيل الاتصال المذكورة أعلاه',
  covid_19: 'كوفيد-19',
  regular: 'عادي',
  insurance_summary: 'ملخص التأمين',
  one_week: 'اسبوع واحد',
  apr_23: '18 أبريل 23',
  Andrew_Martin: 'أندرو مارتن',
  Samantha_Andrew: 'سامانثا أندرو',
  John_Andrew: 'جون أندرو',
  dob: 'تاريخ الميلاد',
  civil_id: 'البطاقة المدنية',
  policy: 'سياسة',
  policy_period: 'فترة السياسة',
  policy_start_date: 'تاريخ بدء السياسة',
  insurance: 'تأمين',
  payment_summary: 'ملخص الدفع',
  ticket_price: 'سعر التذكرة',
  tax: 'ضريبة',

  reviews_rating: 'التعليقات والتقييمات',
  note_form: 'ملاحظة: سوف تتلقى التحديثات على تفاصيل الاتصال المذكورة أعلاه',
  star_rating: 'إعطاء تصنيف النجوم',
  share_your_review: 'شارك رأيك',
  title: 'عنوان',
  testmonial_content: 'لقد كانت تجربة رائعة للسفر مع طيران كندا، وكانت الرحلة مريحة وكان الطاقم متواضعًا للغاية',
  add_testmonial: 'إضافة الشهادات',
  basic_information: 'معلومات اساسية',
  passport_expire: 'انتهاء جواز السفر',
  passport_issuer_country: 'الدولة المصدرة لجواز السفر',
  My_Profile: 'ملفي',
  no_co_pax: 'لم تتم إضافة أي مشاركة',
  enter_password: 'أدخل كلمة المرور',
  re_enter_password: 'أعد إدخال كلمة المرور الجديدة',
  add_co_pax: 'أضف شارك باكس',
  first_name: 'الاسم الأول',
  last_name: 'اسم العائلة',
  gender: 'حدد نوع الجنس',

  Select_Nationality: 'اختر الجنسية',
  refund_details: 'تفاصيل استرداد الأموال',
  total_cost_paid: 'إجمالي التكلفة المدفوعة',
  Cancellation_Charges: 'رسوم الإلغاء',
  Refundable_Amount: 'المبلغ القابل للاسترداد',
  refund_content: 'لقد كانت تجربة رائعة للسفر مع طيران كندا، وكانت الرحلة مريحة وكان الطاقم متواضعًا للغاية.',
  reason: 'سبب',
  change_date: 'تغيير التاريخ',
  cancel: 'يلغي',
  procced_to_pay: 'المضي قدما في الدفع',
  rixos_premium: 'ريكسوس بريميوم دبي جي بي آر',
  room_selection: 'اختيار الغرفة',
  room: 'غرفة',
  bedroom_2: '2 غرف نوم',
  living_rooms_1: '1 غرف معيشة',
  bathrooms_2: '2 حمام',
  kitchen_1: '1 مطبخ',
  location: 'موقع',
  Room_Details: 'تفاصيل الغرفة',
  mapcard_content: 'شارع الممشى، الممشى، جميرا بيتش ريزيد, دبي 643660 الإمارات العربية المتحدة',
  available_hotel: 'الفنادق المتاحة',
  booking_id: 'أدخل معرف الحجز',
  smartpayment_note: 'ملاحظة: أدخل معرف الحجز الذي تمت مشاركته معك عبر البريد الإلكتروني.',
  managebooking_note: 'ملاحظة: سيتعين عليك تسجيل الدخول أو الاشتراك إذا لم يكن لديك حساب للمتابعة',
  adult: 'بالغ',
  child: 'طفل',
  payment_method: 'طريقة الدفع او السداد',
  payment_status: 'حالة السداد',
  payment_reference: 'إشارة دفع',
  booking_details: 'تفاصيل الحجز',
  status: 'حالة',
  payment: 'قسط',
  no_of_rooms: 'عدد الغرف',
  for_users: 'للمستخدمين',
  forgot_content: 'أدخل عنوان البريد الإلكتروني المرتبط بحسابك، وسنرسل رمز التحقق لإعادة تعيين كلمة المرور الخاصة بك.',
  sign_in_message: "بوابتك للمغامرة. تسجيل الدخول واستكشاف!",
  email: "الإيميل",
  password: "كلمة المرور",
  forgot_password: "نسيت كلمة المرور؟",
  dont_have_account: "ليس لديك حساب؟",
  sign_up: "تسجيل جديد",
  reset_password: "إعادة تعيين كلمة المرور",
  reset_password_message: "أدخل عنوان البريد الإلكتروني المرتبط بحسابك وسنرسل رمز التحقق لإعادة تعيين كلمة المرور الخاصة بك",
  submit: "إرسال",
  otp_verification: "التحقق من OTP",
  otp_verification_message: "الرجاء إدخال كلمة المرور لمرة واحدة (OTP) التي تلقيتها على",
  expires_in: "ينتهي الساعة",
  didnt_receive_code: "لم تتلق الرمز؟",
  resend: "إعادة إرسال",
  verify: "تأكيد",
  new_password_message: "يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمات المرور المستخدمة سابقًا",
  confirm_password: "تأكيد كلمة المرور",
  create_account_message: "انضم إلينا ودعنا نحقق ذلك! سنتعامل مع التفاصيل بينما تستمتع بالمغامرة",
  upload_photo: "حمل الصورة",
  mobile: "الهاتف المحمول",
  enter_booking_id: 'أدخل معرف الحجز',
  manage_bookings_contents: 'ملاحظة: سيتعين عليك تسجيل الدخول أو الاشتراك إذا لم يكن لديك حساب للمتابعة.',
  subscription_confirm_message: "احصل على عروض السفر والعروض الخاصة وغيرها من المعلومات من Flytern عبر البريد الإلكتروني.",
  terms_agree_message: "من خلال الاستمرار، فإنك توافق على لدينا",
  already_have_an_account: "هل لديك حساب؟",
  terms_n_conditions: "الأحكام والشروط",
  privacy_policy: "سياسات الخصوصية",
  flights: "الرحلات الجوية",
  hotels: "الفنادق",
  packages: "الحزم",
  activities: "أنشطة",
  tour_date: "تاريخ الجولة",
  profile: "حساب",
  payment_refrence: 'إشارة دفع',
  booking_deatils: 'تفاصيل الحجز',
  smart_payment: "الدفع الذكي",
  travel_insurance: "تأمين السفر",
  extra_miles: "أميال إضافية",
  gift_cards: "بطاقات الهدايا",
  travel_stories: "قصص السفر",
  my_travel_stories: "قصص سفري",
  travel_tips: "نصائح سفر",
  manage_bookings: "إدارة الحجوزات",
  my_bookings: "حجوزاتي",
  app_settings: "الإعدادات",
  info: "معلومة",
  faq: "الأسئلة الشائعة",
  rating: "تقييم",
  logout: "تسجيل خروج",
  view_profile: "عرض الصفحة الشخصية",
  edit_profile: "تعديل الملف الشخصي",
  change_password: "تغيير كلمة المرور",
  one_way: "طريقة واحدة",
  round_trip: "جولة",
  multi_city: "مدينة متعددة",
  to: "من",
  more: 'أكثر',
  from: "الى",
  travel_date: "تاريخ السفر",
  return_date: "تاريخ العودة",
  passengers_cabin_class: "الركاب / درجة المقصورة",
  promo_code: "كود الخصم",
  direct_flight: "الطيران المباشر",
  search_flights: "البحث عن الرحلات الجوية",
  add_another_city: "أضف مدينة أخرى",
  recommended_for_you: "موصى به لك",
  popular_destinations: "الوجهات الشعبية",
  see_all: "اظهار الكل",
  destination: "وجهة",
  checkin: "تحقق في",
  checkout: "الدفع",
  users: "المستخدمين",
  nationality: "جنسية",
  search_hotels: "بحث عن الفنادق",
  select_destination: "حدد وجهتك",
  lorem_ipsum_description: "لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.",
  login_or_signup_to: "تسجيل الدخول أو حساب جديد",
  experience_pound: "تجربة باوند",
  login_to: "الدخول",
  or_contiune_with: "أو أكمل مع",
  facing_login_issues: "هل تواجه مشكلات في تسجيل الدخول؟",
  call_support: "اتصل بالدعم",
  cal_now: "اتصل الآن",
  whatsapp: "واتساب",
  new_account: "حساب جديد",
  join: "الإنضمام",
  name: "الإسم",
  terms_confirmation_message: "من خلال تسجيلك فإنك توافق على",
  enter_mobile_message: "أدخل هاتفك المحمول المسجل أدناه لتلقي رسالة التأكيد",
  send_otp: "أرسل رسالة التأكيد",
  verify_otp: "تأكد من رسالة التأكيد",
  enter_otp_message: "أدخل رسالة التأكيد المستلمة على جهازك للتحقق من هويتك",
  one_time_password: "كلمة السر لمرة واحدة",
  enter_password_message: "أدخل كلمة مرور جديدة مكونة من 5 أحرف على الأقل",
  set_new_password: "تعيين كلمة مرور جديدة",
  my_account: "حسابي",
  my_addresses: "العناونين",
  past_orders: "الطلبات السابقة",
  pound_wallet: "المحفظة",
  report_a_problem: "الإبلاغ عن مشكلة",
  instagram: "انستغرام",
  privacy: "الخصوصية",
  signup: "اشتراك",
  account_created: "تم إنشاء حسابك بنجاح",
  tap_to_continue: "انقر في أي مكان للمتابعة",
  signup_and_receive_points: "سجل واحصل على 20 نقطة",
  welcome_credit: "نقاط ترحيبية",
  notifications: "الإشعارات",
  save: "حفظ",
  current_password: "كلمة المرور الحالية",
  add_new_Address: "أضف عنوان جديد",
  update_Address: "تحديث العنوان",
  edit: "تعديل",
  view: "عرض",
  delete: "حذف",
  search_for_your_address: "ابحث عن عنوانك",
  enter_manually: "أدخل يدويًا",
  confirm: "تأكيد",
  address_name: "اسم العنوان",
  select_location: "اختر المكان من الخريطة",
  area: "المنطقة",
  block: "القطعة",
  street: "الشارع",
  locality: "المحافظة",
  avenue: "الجادة",
  house_building: "المنزل أو المبنى",
  floor: "الطابق",
  flat: "الشقة",
  extra_directions: "اتجاهات اضافية",
  home: "منزل",
  office: "مكتب",
  alternate_mobile_number: "رقم الهاتف النقال البديل",
  events_notification: "اشعارات المناسبات",
  promotional_notifications: "اشعاراتترويجية",
  orders_notifications: "اشعارات الطلبات",
  langauge: "اللغة",
  view_order: "عرض الطلب",
  order_details: "تفاصيل الطلب",
  created: "تم",
  confirmed: "مؤكد",
  accepted: "تم القبول",
  preparing: "يتم التحضير",
  out_for_delivery: "خارج للتوصيل",
  ready_to_pickup: "جاهز للاستلام",
  dispatched: "مُرسَل",
  delivered: "تم التوصيل",
  completed: "مكتمل",
  cancelled: "ألغيت",
  order: "اطلب",
  repeat_order: "كرر الطلب",
  summary: "ملخص",
  subtotal: "الاجمالي",
  delivery_charges: "رسوم التوصيل",
  total: "الاجمالي الطلب",
  deliver_to: "التوصيل الى",
  delivery_time: "وقت التوصيل",
  knet: "ك-نيت",
  credit_card: "بطاقة إئتمان",
  card_message: "الرسالة في البطاقه",
  message: "رسالة",
  shared_feelings_with_video: "لقد قمت بمشاركة فيديو",
  shared_feelings_with_audio: "لقد قمت بمشاركة مقع صوتي",
  shared_feelings_with_photo: "لقد قمت بمشاركة صورة",
  shared_feelings_with_link: "لقد قمت بمشاركة رابط",
  earned_points_by_completing_order: "لقد ربحت 10 نقاط باوند بإكمالك هذا الطلب",
  kwd: "د.ك",
  points: "نقاط",
  history: "طالبات سابقة",
  new_order: "طلب جديد",
  welcome_gift: "هدية ترحيبية",
  refund: "استرداد",
  reported_problems: "المشاكل ااتي تم الابلاغ عنها",
  reported_problem: "مشكلة تم الابلاغ عنها",
  no_reported_problems: "لم يبلغ عن أي مشاكل",
  we_will_help_you: "سنساعدك بعد وصف المشكلة في الفقرة أدناه",
  your_message: "رسالتك",
  attach_a_file_to_problem: "أرفق ملفًا لمساعدتنا في فهم مشكلتك",
  send: "ارسل",
  reported: "تم الابلاغ",
  inspecting: "جاري التدقيق",
  rectified: "مصحح",
  best_sellers: "الافضل مبيعا",
  shop_by_categories: "تسوق حسب الفئات",
  new_In: "الجديد",
  pound_stars: "نجوم باوند",
  shop_by_diet_type: "تسوق حسب النظام الغذائي",
  shop_by_occassions: "تسوق حسب المناسبات",
  browse_categories: "تسوق حسب المناسبات",
  order_is_scheduled: "جدولة الطلب",
  after: "بعد",
  between: "بين",
  and: "و",
  order_is_delivered: "تم التوصيل في 15/10/22 الوقت 11:50 AM",
  search: "البحث",
  search_food: "ابحث واعثر على أطباقك المفضلة",
  no_results: "لايوجد نتائج",
  no_address: "لم نجد العنوان",
  no_order: "لايوجد طلبات للعرض",
  no_products_in_cart: "السلة فارغة",
  no_results_message: "نحن نعمل على الحصول على المزيد من النتائج لك. حاول مرة اخرى",
  stars: "النجوم",
  related_products: "منتجات ذات صله",
  issue_with_the_order: "مشكلة في الطلب؟",
  product_details: "تفاصيل المنتج",
  serving_size: "حجم الوجبة",
  width: "العرض",
  height: "الطول",
  weight: "الوزن",
  preparation_time: "وقت التحضير",
  description: "الوصف",
  read_more: "...اقرأ أكثر",
  read_less: "أقرأ أقل",
  select_Country: 'حدد الدولة',
  contact_us: 'اتصل بنا',
  recomended: 'موصى به',
  popular_destination: 'الوجهات الشعبية',
  help_center: 'مركز المساعدة',

}

export const profileSidebarData = [
  {
    name: language === 'ar' ? arabic_translation.my_bookings : 'My Bookings',
    image: <BookingIconSvg color={"#9C9C9C"} />,
    route: '/profile/my-bookings'
  },
  {
    name: language === 'ar' ? arabic_translation.my_travel_stories : 'My Travel Stories',
    image: <TravelStoriesIconSvg color={"#9C9C9C"} />,
    route: '/profile/my-travel-stories'
  },
  // {
  //   name: 'My Extra Miles',
  //   image: <ExtraMilesIconSvg color={"#9C9C9C"} />,
  //   route: '/profile/extra-miles'
  // },
  {
    name:  language === 'ar' ? arabic_translation.change_password : 'Change Password',
    image: <ChangePasswordIconSvg color={"#9C9C9C"} />,
    route: '/profile/change-password'
  },
]

export const generalSidebarData = [
  {
    name: language === 'ar' ? arabic_translation.smart_payment : 'Smart Payment',
    image: <BookingIconSvg color={"#9C9C9C"} />,
    route: '/general/smart-payment'
  },
  {
    name: language === 'ar' ? arabic_translation.setting : 'Settings',
    image: <SettingsIconSvg color={"#9C9C9C"} />,
    route: '/general/settings'
  },
  // {

  //   name:  language === 'ar' ? arabic_translation.gift_cards : 'Gift Cards',

  //   image: <BookingIconSvg color={"#9C9C9C"} />,
  //   route: '/general/gift-cards'
  // },
  {
    name: language === 'ar' ? arabic_translation.travel_stories : 'Travel Stories',
    image: <TravelStoriesIconSvg color={"#9C9C9C"} />,
    route: '/general/travel-stories'
  },
  // {
  //   name: language === 'ar' ? arabic_translation.travel_tips:'Travel Tips',
  //   image: <TravelTipsIconSvg color={"#9C9C9C"} />,
  //   route: '/general/travel-tips'
  // },
  // {
  //   name: language === 'ar' ? arabic_translation.manage_bookings:'Manage Bookings',
  //   image: <BookingIconSvg color={"#9C9C9C"} />,
  //   route: '/general/manage-booking'
  // },

  {
    name: language === 'ar' ? arabic_translation.recomended : 'Recomended',
    image: <TravelAssistanceIconSvg color={"#9C9C9C"} />,
    route: '/general/Recomended'
  },
  {
    name: language === 'ar' ? arabic_translation.popular_destination : 'Popular Destination',
    image: <TravelStoriesIconSvg color={"#9C9C9C"} />,
    route: '/general/popular-destination'
  },
  {
    name: language === 'ar' ? arabic_translation.help_center : 'Help Center',
    image: <FaqIconSvg color={"#9C9C9C"} />,
    route: '/general/help-center'
  },

  // {
  //   name: language === 'ar' ? arabic_translation.rating:'Rating',
  //   image: <RatingIconSvg color={"#9C9C9C"} />,
  //   route: '/general/rating'
  // },
  {
    name: language === 'ar' ? arabic_translation.travel_assistance : 'Contact Us',
    image: <TravelAssistanceIconSvg color={"#9C9C9C"} />,
    route: '/general/contact-us'
  },
]


import React, { useEffect } from 'react'
import TitleCard from '../../../shared/components/TitleCard'
import { TermsAndConditionIconSvg } from '../../../assets/svg'
import { useDispatch, useSelector } from 'react-redux';
import { Privacysuccess } from '../genaralSlice';
import { getAboutApi, getPrivacyApi } from '../apiServices';

const PrivacyPolicy = () => {
    const dispatch = useDispatch();

  useEffect(() => {
    const getTerms = async () => {
      const data = await getPrivacyApi();

      if (data) {
        dispatch(Privacysuccess(data));
      }
    };
    getTerms();
  }, []);
  const terms = useSelector(
    (item) => item?.genralState?.privacypolicy?.information?.[0]?.content
  );
  
  return (
    <TitleCard
    title={
      <>
        <div className="flex items-center gap-2 ">
          <TermsAndConditionIconSvg color={"#066651"} />
          <h4>Privacy Policy</h4>
        </div>
      </>
    }
  >
    {terms && (
      <div
        className=" mt-4"
        dangerouslySetInnerHTML={{ __html: terms }}
      ></div>
    )}
  </TitleCard>
  )
}

export default PrivacyPolicy
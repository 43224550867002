import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import exploresReducer from "../applications/home/exploresSlice";
import profileReducer from "../applications/profile/profileSlice";
import sharedReducer from "../shared/sharedSlice";
import packageReducer from "../applications/package/packageSlice";
import flightReducer from "../applications/flight/flightSlice";
import insuranceReducer from "../applications/insurance/insuranceSlice";
import genaralReducer from "../applications/general/genaralSlice";
import activityReducer from "../applications/activities/activitySlice";
import paymentReducer from "../applications/paymentMethod/paymentSlice";
import hotelReducer from "../applications/hotel/hotelSlice";

const reducer = combineReducers({
  exploresState: exploresReducer,
  profileState: profileReducer,
  sharedState: sharedReducer,
  packageState: packageReducer,
  flightState: flightReducer,
  insuranceState: insuranceReducer,
  genralState: genaralReducer,
  activityState: activityReducer,
  paymentState: paymentReducer,
  hotelState: hotelReducer,
});

const store = configureStore({
  reducer,
  middleware: [thunk],
});

export default store;

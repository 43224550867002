
import HeaderLayout from "../../hoc/HeaderLayout";
import Layout from "../../hoc/Layout"
import { lazy } from "react"
const FlightResults = lazy(() => import("./containers/FlightResults"));
const FlightDetails = lazy(() => import("./containers/FlightDetails"));

export const flightRoutes = [
  {
    path: "/flight/search",
    exact: true,
    component: <HeaderLayout><Layout > <FlightResults /> </Layout></HeaderLayout>
  },
  {
    path: "/ar/flight/search",
    exact: true,
    component: <HeaderLayout lang="ar"  ><Layout > <FlightResults lang="ar" /> </Layout></HeaderLayout>
  },
  {
    path: "/flight/details/:objectID/:index",
    exact: true,
    component: <HeaderLayout><Layout > <FlightDetails /> </Layout></HeaderLayout>
  },
  {
    path: "/ar/flight/details/:objectID/:index",
    exact: true,
    component: <HeaderLayout lang="ar" > <Layout > <FlightDetails lang="ar" /> </Layout></HeaderLayout>
  },
]
import React, { Suspense, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { homeRoutes } from './applications/home/homeRoutes';
import { flightRoutes } from './applications/flight/flightRoutes';
import { profileRoutes } from './applications/profile/profileRoutes'
import { PackageRoutes } from './applications/package/packageRoutes';
import { ActivitiesRoutes } from './applications/activities/activitiesRoutes';
import { hotelRoutes } from './applications/hotel/hotelRoutes';
import { generalRoutes } from './applications/general/generalRoutes';
import { insuranceRoutes } from './applications/insurance/insuranceRoutes';
import { PaymentRoutes } from './applications/paymentMethod/paymentRoutes';
import { authRoutes } from './applications/auth/authRoutes';
import { authApiService } from './utils/authApi';
import { setGlobalCookie, getGlobalCookie, checkApiStatus } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { setContactDetails, setTranslation } from './shared/sharedSlice';
import { getCountryApi, getFetchLanguageApi, getNotificationApi } from './shared/apiServices';
import { getIntialInfoApi, saveDeviceLanguage } from './applications/general/apiServices';
import './App.css';
import { useMediaQuery } from 'react-responsive';
import MobileNavigator from './applications/home/components/MobileNavigator';
import translation from './utils/translation.json'
import { countryAndLanguageSuccess } from './applications/general/genaralSlice';


function App() {
  const dispatch = useDispatch()
  const location = window.location.pathname
  const { profile, mobileCountryList, contactDc, selectedLanguageAndCountry } = useSelector(state => state.sharedState)
  let accessToken = getGlobalCookie('accessToken');
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  let defaultCountry = mobileCountryList.find(item => item.isDefault === 1)
  const handleToken = useCallback(() => {
    if (!accessToken) {
      authApiService('/api/Auths/Token', { "DeviceID": "00700834" })
        .then((response) => {
          setGlobalCookie('accessToken', JSON.stringify(response.data.data.accessToken));
          setGlobalCookie('refreshToken', JSON.stringify(response.data.data.refreshToken));
        })
        .catch((error) => {
        });
    }
  }, [accessToken]);

  const handleContactDc = useCallback(() => {
    if (profile && Object.keys(profile).length) {
      dispatch(
        setContactDetails({
          email: profile?.email,
          countryCode: profile?.phoneCountryCode,
          mobileNumber: profile?.phoneNumber,
        })
      );
    } else {
      if (defaultCountry) {
        dispatch(
          setContactDetails({
            ...contactDc,
            countryCode: defaultCountry?.code,
            mobileCntry: defaultCountry?.code,
          })
        );
      }
    }
  }, [profile]);

  useEffect(() => {
    handleToken()
  }, [handleToken]);

  useEffect(() => {
    handleContactDc()
    dispatch(getCountryApi)
    dispatch(getIntialInfoApi)
  }, [handleContactDc])

  useEffect(() => {
    dispatch(getFetchLanguageApi)
  }, [])

  useEffect(() => {
    dispatch(getNotificationApi)
  }, [])

  useEffect(() => {
    const isArabic = location.includes("/ar");
    const language = isArabic ? 'ar' : 'en';
    const notificationToken = isArabic ? 'ar' : 'en';
    const saveLanguageAndDispatch = async (language, notificationToken) => {
      const countryCode = "KW";
      const notificationEnabled = false;
      const response = await saveDeviceLanguage({ countryCode, language, notificationEnabled, notificationToken });
      if (checkApiStatus(response)) {
        dispatch(countryAndLanguageSuccess({ countryCode, language, notificationEnabled, notificationToken }));
        const index = language === 'ar' ? 1 : 0;
        dispatch(setTranslation(translation[index].texts));
      }
    };



    saveLanguageAndDispatch(language, notificationToken);
  }, [dispatch, location]);
  return (
    <div className={`App ${selectedLanguageAndCountry?.language?.code === "ar" || location.includes("/ar") ? 'rtl font-arabic' : 'font-inter'}`}>
      <Suspense >
        <Router>
          {isMobile &&
            <MobileNavigator />
          }
          <Routes>
            {
              [...authRoutes, ...homeRoutes, ...flightRoutes, ...profileRoutes, ...PackageRoutes, ...ActivitiesRoutes, ...hotelRoutes, ...generalRoutes, ...insuranceRoutes, ...PaymentRoutes].map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={route.component}
                  exact={route.exact}
                />
              ))
            }
          </Routes>
        </Router>
      </Suspense>
    </div >
  );
}

export default App;

import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { FlyternLogo, HomeSvg } from "../../assets/svg";
import { useMediaQuery } from "react-responsive";
import HeaderMore from "./HeaderMore";
import HeaderProfile from "./HeaderProfile";
import HeaderNotifiction from "./HeaderNotifiction";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetailApi, getlanguageSwitchApi } from "../apiServices";
import { Link, useNavigate } from "react-router-dom";
import MobileHeaderMenu from "./MobileHeaderMenu";
export function useIsTabletAndMobile() {
  const isTabletAndMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return isTabletAndMobile;
}

const Header = ({lang}) => {
  const dispatch = useDispatch();
  const { selectedLanguageAndCountry } = useSelector((state) => state.sharedState);
  const { translation } = useSelector((state) => state.sharedState)
  const { genericLoader } = useSelector(state => state.flightState)
  const { saveTravellerLoader } = useSelector(state => state.insuranceState)
  const { paymentWaitLoader } = useSelector(state => state.paymentState)
  const isTabletAndMobile = useIsTabletAndMobile()
  useEffect(() => {
    dispatch(getProfileDetailApi);
    dispatch(getlanguageSwitchApi);
  }, []);

  const location = useLocation();
  const validPaths = ["/", "/ar", "/flights", "/hotels", "/ar/flights", "/ar/hotels"];
  const isHome = validPaths.includes(location.pathname);



  return (
    <>
      {
        genericLoader || saveTravellerLoader || paymentWaitLoader ?
          null :
          <div
            className={`box-border flex items-center justify-between w-full h-20 border-b ${isHome ? "bg-white" : "bg-emerald-800"
              }  `}
          >
            <div className="container flex items-center justify-between px-4 mx-auto">
              <Link className="hidden h-8 cursor-pointer w-36 lg:block" to={lang === "ar" ? "/ar" : "/"}>
                <FlyternLogo color={isHome ? "#066651" : "#fff"} width={'141px'} height={"33px"} />
              </Link>
              {isTabletAndMobile ? (
                <div className="flex items-center justify-between w-full gap-5">
                  <div className="flex flex-row items-center gap-4">
                    <MobileHeaderMenu />
                    <Link className="h-full cursor-pointer w-36" to={"/"}>
                      <FlyternLogo color={isHome ? "#066651" : "#fff"} width={'141px'} height={"33px"} />
                    </Link>
                  </div>
                  {/* <HeaderProfile /> */}
                  <HeaderNotifiction />
                </div>
              ) : (
                <div className="flex items-center gap-9">
                  <Link to="/"
                    className={`text-sm font-normal text-center cursor-pointer ${isHome ? "text-black" : "text-white"
                      }`}
                  >
                    <div className="flex items-center gap-4 cursor-pointer">
                      {/* <HomeSvg color={isHome ? "#000" : "#fff"} /> */}
                      {translation?.home}
                    </div>
                  </Link>
                  <Link to="/profile/my-bookings">
                    <div className="flex items-center gap-2 cursor-pointer">
                      {/* {
                        isHome ?
                          <img className="w-5 h-5" src={require('../../assets/mybookingblack.png')} alt="" />
                          : <img className="w-5 h-5" src={require('../../assets/mybookingwhite.png')} alt="" />

                      } */}
                      <h3 className={`text-sm font-normal text-center cursor-pointer ${isHome ? "text-black" : "text-white"
                        }`}>{translation?.my_bookings}</h3>
                    </div>
                  </Link>
                  <Link to="/general/help-center">
                    <div className="flex items-center gap-2 cursor-pointer">
                      {/* {
                        isHome ?
                          <img className='w-6 h-6' src={require('../../assets/FAq1.png')} alt="" srcset="" />
                          : <img className='w-6 h-6' src={require('../../assets/helpcenterwhite.png')} alt="" srcset="" />

                      } */}
                      <h3 className={`text-sm font-normal text-center cursor-pointer ${isHome ? "text-black" : "text-white"
                        }`}>{translation?.help_center}</h3>
                    </div>
                  </Link>
                  <Link to="/general/contact-us">
                    <div className="flex items-center gap-2 cursor-pointer">
                      {/* {
                        isHome ?
                          <img className="w-5 h-5" src={require('../../assets/contactus.png')} alt="" />
                          : <img className="w-5 h-5" src={require('../../assets/contactwhite.png')} alt="" />

                      } */}
                      <h3 className={`text-sm font-normal text-center cursor-pointer ${isHome ? "text-black" : "text-white"
                        }`}>{translation?.contact_us}</h3>
                    </div>
                  </Link>

                  <HeaderMore />
                  {/* <HeaderLanguage /> */}
                  <Link to="/general/settings">
                    <div className="flex items-center gap-2 cursor-pointer">
                      {
                        <img className="w-5 h-5" src={selectedLanguageAndCountry?.country?.flag} alt="" />

                      }
                      <h3 className={`text-sm font-normal text-center cursor-pointer ${isHome ? "text-black" : "text-white"
                        }`}>{selectedLanguageAndCountry?.language?.name ? selectedLanguageAndCountry?.language?.name : "English"} / {selectedLanguageAndCountry?.country?.countryName_Ar}</h3>
                    </div>
                  </Link>
                  <HeaderNotifiction />
                  <HeaderProfile />
                </div>
              )}
            </div>
          </div>
      }
    </>
  );
};

export default Header;

import { lazy } from "react";
import Layout from "../../hoc/Layout";
import HeaderLayout from "../../hoc/HeaderLayout";
const ActivityCityList = lazy(() => import("./containers/ActivityCityList"));
const ActivitiesDetail = lazy(() => import("./containers/ActivitiesDetail"));
const ActivityList = lazy(() => import("./containers/ActivityList"));

export const ActivitiesRoutes = [
  {
    path: "/activityCities",
    exact: true,
    component: (
      <HeaderLayout>
        <Layout>
          {" "}
          <ActivityCityList />{" "}
        </Layout>
      </HeaderLayout>
    ),
  },
  {
    path: "/activityCities/activities/details/:objectId",
    exact: true,
    component: (
      <HeaderLayout>
        <Layout>
          <ActivitiesDetail />
        </Layout>
      </HeaderLayout>
    ),
  },
  {
    path: "/activityCities/activities/:cityID",
    exact: true,
    component: (
      <HeaderLayout>
        <Layout>
          <ActivityList />
        </Layout>
      </HeaderLayout>
    ),
  },
];

import React, { useEffect } from "react";
import TitleCard from "../../../shared/components/TitleCard";
import { TermsAndConditionIconSvg } from "../../../assets/svg";
import { Aboutsuccess } from "../genaralSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAboutApi } from "../apiServices";

const AboutUs = () => {
  const dispatch = useDispatch();
  const { selectedLanguageAndCountry } = useSelector((state) => state.sharedState);
  const { translation } = useSelector((state) => state.sharedState);
  useEffect(() => {
    const getTerms = async () => {
      const data = await getAboutApi();

      if (data) {
        dispatch(Aboutsuccess(data));
      }
    };
    getTerms();
  }, []);
  const terms = useSelector(
    (item) => item?.genralState?.aboutus?.information?.[0]?.content
  );

  return (
    <TitleCard
      title={
        <>
          <div className="flex items-center gap-2 ">
            <TermsAndConditionIconSvg color={"#066651"} />
            <sh4>{translation?.about_us}</sh4>
          </div>
        </>
      }
    >
      {terms && (
        <div
          className={`mt-4 ${selectedLanguageAndCountry?.language?.code === "ar" ? 'font-arabic' : 'font-inter'} `}
          dangerouslySetInnerHTML={{ __html: terms }}
        ></div>
      )}
    </TitleCard>
  );
};

export default AboutUs;

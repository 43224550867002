import HeaderLayout from '../../hoc/HeaderLayout';
import Layout from '../../hoc/Layout';

import { lazy } from 'react';

const PaymentMethod = lazy(() => import('./containers/PaymentMethod'));
const PaymentSummary = lazy(() => import('./containers/PaymentSummary'));

export const PaymentRoutes = [
  {
    path: '/payment-method/',
    exact: true,
    component: <HeaderLayout><Layout><PaymentMethod /></Layout></HeaderLayout>,
  },
  {
    path: '/payment-summary/',
    exact: true,
    component: <HeaderLayout><Layout><PaymentSummary /></Layout></HeaderLayout>,
  },
  {
    path: '/payment-summary/:bookingIdRef',
    exact: true,
    component: <HeaderLayout><Layout><PaymentSummary /></Layout></HeaderLayout>,
  }
]
import { lazy } from "react";
import Layout from "../../hoc/Layout";
import HeaderLayout from "../../hoc/HeaderLayout";
const Insurance = lazy(() => import("./containers/Insurance"))
const InsuranceSummary = lazy(() => import("./containers/InsuranceSummary"))



export const insuranceRoutes = [
  {
    path: "/insurance",
    exact: true,
    component: <HeaderLayout><Layout><Insurance /></Layout></HeaderLayout>
  },
  {
    path: "/ar/insurance",
    exact: true,
    component: <HeaderLayout lang="ar"><Layout><Insurance lang="ar" /></Layout></HeaderLayout>
  },
  {
    path: "/insurance/details",
    exact: true,
    component: <HeaderLayout><Layout><InsuranceSummary /></Layout></HeaderLayout>
  },
  {
    path: "/ar/insurance/details",
    exact: true,
    component: <HeaderLayout lang="ar"><Layout><InsuranceSummary lang="ar" /></Layout></HeaderLayout>
  },
]


import { Avatar, List, ListItem, ListItemPrefix, Popover, PopoverContent, PopoverHandler, Typography, popover } from '@material-tailwind/react'
import React, { useState } from 'react'
import profileImg from '../../assets/dummy-image-profile.jpg'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { clearAllCookies, setGlobalCookie } from '../../utils';
import { arabic_translation } from '../../constants'
import { authApiService } from '../../utils/authApi'

const HeaderProfile = () => {
    const { profile } = useSelector(state => state.sharedState)
    const navigate = useNavigate()
    const userLogout = () => {
        clearAllCookies();
        setTimeout(() => {
            authApiService('/api/Auths/Token', { "DeviceID": "00700834" })
                .then((response) => {
                    setGlobalCookie('accessToken', JSON.stringify(response.data.data.accessToken));
                    setGlobalCookie('refreshToken', JSON.stringify(response.data.data.refreshToken));
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 1000);
                })
                .catch((error) => {
                });
        }, 1000);
    }

    const [openPopover, setOpenPopover] = useState(false);

    const triggers = {
        onClick: () => setOpenPopover(!openPopover),
    };
    const { translation } = useSelector((state) => state.sharedState)
    const { selectedLanguageAndCountry } = useSelector(state => state.sharedState)
    const handleSignInClick = () => {
        // Store the current path in local storage
        localStorage.setItem('redirectPath', JSON.stringify(`${window.location.pathname}#contact_details`));
        // Redirect to the login page
        navigate('/login');
    };
    
    
    return (
        <div className="flex items-center cursor-pointer" {...triggers}>
            {
                profile && Object.keys(profile).length ?
                    <Popover placement="bottom" open={openPopover} handler={setOpenPopover}>
                        <PopoverHandler {...triggers}>
                            {profile && Object.keys(profile).length ? <img src={profile.imgUrl} alt={profile.firstName} className='object-cover w-12 h-12 rounded-full ' /> :
                                null
                            }
                        </PopoverHandler>
                        <PopoverContent className="z-10 flex flex-col items-center justify-center mt-6 w-72 ">
                            {
                                profile && Object.keys(profile).length ?
                                    <div className="flex items-center gap-4 pb-4 mt-8 mb-4 w-60 ">
                                        <Avatar src={profile.imgUrl} alt={profile.firstName} />
                                        <div>
                                            <Typography variant="h6" color="blue-gray" className='text-base font-medium text-black'>
                                                {profile && profile?.firstName || ''} {profile && profile?.lastName || ''}
                                            </Typography>
                                            <Typography variant="small" color="gray" className="text-xs font-normal " style={{ color: '#A3A3A3' }}>
                                                {profile && profile?.email || ''}
                                            </Typography>
                                            <Typography variant="small" color="gray" className="text-sm font-normal cursor-pointer " style={{ color: '#066651' }} onClick={() => { navigate('/profile') }} >
                                                {selectedLanguageAndCountry?.language?.code === "ar" ? arabic_translation.view_profile : 'View Profile'}
                                            </Typography>
                                        </div>
                                    </div> : null
                            }
                            {profile && Object.keys(profile).length ?
                                <List className="p-0">
                                    <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => { navigate('/profile/my-bookings') }}  >
                                        <ListItemPrefix>
                                            <img className='w-6 h-6' src={require('../../assets/share.png')} alt="" srcSet="" />
                                        </ListItemPrefix>
                                        {translation?.my_bookings}
                                    </ListItem>
                                    <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => { navigate('/profile/my-travel-stories') }}>
                                        <ListItemPrefix>
                                            <img className='w-6 h-6' src={require('../../assets/travelStories.png')} alt="" srcSet="" />
                                        </ListItemPrefix>
                                        {translation?.my_travel_stories}
                                    </ListItem>

                                    <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60'>
                                        <ListItemPrefix>
                                            <img className='w-6 h-6' src={require('../../assets/routes.png')} alt="" srcSet="" />
                                        </ListItemPrefix>
                                        {translation?.extra_miles}
                                    </ListItem>
                                    <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60 ' onClick={() => { navigate('/profile/change-password') }}>
                                        <ListItemPrefix>
                                            <img className='w-6 h-6' src={require('../../assets/lock.png')} alt="" srcSet="" />
                                        </ListItemPrefix>
                                        {translation?.change_password}
                                    </ListItem>
                                    <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => userLogout()}>
                                        <ListItemPrefix>
                                            <img className='w-6 h-6' src={require('../../assets/Logout.png')} alt="" srcSet="" />
                                        </ListItemPrefix>
                                        {translation?.logout}
                                    </ListItem>
                                </List> :
                                <List className="p-0">
                                    <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => navigate('/login')}>{translation?.login}</ListItem>
                                </List>
                            }

                        </PopoverContent>
                    </Popover> :
                    <div className='p-2 text-sm text-white rounded-md bg-emerald-800' onClick={() =>  navigate('/login')}>{translation?.login} / {translation?.register}</div>
            }
        </div>
    )
}

export default HeaderProfile
import React from 'react'
import TitleCard from '../../../shared/components/TitleCard'
import { Link } from 'react-router-dom'
import star from '../../../assets/icons/star.svg'
import { SeeAllIconSvg } from '../../../assets/svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Recommended = ({ recommends }) => {
  const navigate = useNavigate()
  const isTabletAndMobile = useMediaQuery({ query: "(max-width:767px)" });
  const { translation} = useSelector(state => state.sharedState)
  const { data } = useSelector(state => state.exploresState)
  return (
    <>
      <TitleCard
        title={
          <div className='flex items-center justify-between'>
            <h4 className='text-xs font-bold sm:text-2xl'>{translation?.recommended_for_you}</h4>
            <Link to="/general/recomended" className='flex items-center gap-2 font-normal text-blue-400'>
              <span className='text-xs sm:text-lg '>{translation?.see_all}</span> <SeeAllIconSvg />
            </Link>
          </div>
        }
      >
        <Swiper
          slidesPerView={isTabletAndMobile ? 1.7 : 3}
          modules={[Autoplay]}
          spaceBetween={20}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ 
            delay: 2000,
            disableOnInteraction: true,
           }}
          className='grid grid-cols-3 mt-5 cursor-pointer'

        >
          {data && data.recommends && data.recommends.map((item, index) => (
            <SwiperSlide key={index} onClick={() => navigate(`/packages/details/${item.refID}`) } >
              <div className="inline-flex flex-col items-start justify-start w-full gap-4">
                <img className="w-full rounded-md" src={item.url} alt='' />
                <div className="flex flex-col self-stretch sm:flex-row sm:items-center sm:justify-between">
                  <div className="text-black text-[8px] sm:text-sm font-medium ">{item.name}</div>
                  <div className="justify-start items-center gap-[3px] flex">
                    <div className="text-black text-[7px] sm:text-sm font-medium  flex items-center gap-1">
                      <img className='w-3 h-3 sm:h-6 sm:w-6' src={star} alt="" />
                      <span className='text-[7px] sm:text-sm'>{item.ratings}</span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

      </TitleCard>
    </>
  );
};

export default Recommended;

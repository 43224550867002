import axios from 'axios';
import { generateBrowserFingerprint } from '.';

const username = 'admin';
const password = '9b2684f';

// Create a base64-encoded credentials string
const credentials = btoa(`${username}:${password}`);

export const authAxiosInstance = axios.create({});
authAxiosInstance.defaults.baseURL = "https://travelmate.net/flyternwebapi/"
authAxiosInstance.interceptors.request.use(
  function (config) {
    config.headers["DeviceID"] = generateBrowserFingerprint();
    config.headers["Authorization"] = `Basic ${credentials}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


export const authApiService = (url, data) => {
  return new Promise(function (resolve, reject) {
    authAxiosInstance({
      method: "GET",
      url: url,
      data: data,
    }).then((response) => {
      resolve(response);
    });
  });
}
import React from 'react'
import HistoryIcon from '@mui/icons-material/History';
import { useSelector } from 'react-redux';
import { HeaderBorder } from '../../../shared';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import moment from 'moment/moment';

const RecentSearch = () => {
  const { data: { quickSearch = [] } } = useSelector(state => state.exploresState)
  const navigate = useNavigate()

  const searchRecent = (search) => {
    let payload = {
      ...search,
      searchList: search?.searchList?.map((item) => ({
        ...item,
        departureLabel: item.departure,
        arrivalLabel: item.arrival,
        departureDate: moment(item.departureDate).format('YYYY-MM-DD'),
        returnDate: moment(item.arrivalDate).format('YYYY-MM-DD'),
        returnAnchorEl: null,
        departureAnchorEl: null,
        openDeparture: false,
        openReturn: false,
      }))
    }
    localStorage.setItem('searchData', JSON.stringify(payload))
    navigate("/flight/search");
  }

  return (
    <div>
      {
        quickSearch?.length ?
          <div className='mt-8'>
            <div>
              <div className='flex gap-3 flex-start'>
                <HistoryIcon className='text-orange-400' />
                <h4>Recent Search</h4>
              </div>
              <HeaderBorder />
            </div>
            <div className='flex flex-wrap justify-start gap-2 mt-4 '>
              {quickSearch?.map((item, index) => {
                return (
                  <div className='flex flex-wrap items-center cursor-pointer flex-start' key={index}>
                    {item?.searchList?.length ? item?.searchList?.map((search, searchIndex) => {
                      return (
                        <div key={searchIndex} className='flex-wrap px-2 py-1 transition-all duration-300 border border-orange-400 cursor-pointer rounded-xl hover:bg-orange-400 hover:text-white' onClick={() => searchRecent(item)} >
                          <p>{search.departure} - {search.arrival}</p>
                        </div>
                      )
                    }) : null}
                  </div>
                )
              })}
            </div>
          </div> : null

      }
    </div>
  )
}

export default RecentSearch
import HeaderLayout from "../../hoc/HeaderLayout";
import Layout from "../../hoc/Layout"; // for wrapping the component High Order Component

import Home from "./containers/Home";
import TravelStories from "./containers/TravelStoriesHome";

export const homeRoutes = [
  {
    path: "/",
    exact: true,
    component: <HeaderLayout><Home /></HeaderLayout>
  },
  {
    path: '/flights',
    exact: true,
    component: <HeaderLayout><Home /></HeaderLayout>
  },
  {
    path: "/ar/flights",
    exact: true,
    component: <HeaderLayout lang="ar"><Home lang="ar" /></HeaderLayout>
  },
  {
    path: '/hotels',
    exact: true,
    component: <HeaderLayout><Home /></HeaderLayout>
  },
  {
    path: '/ar/hotels',
    exact: true,
    component: <HeaderLayout lang="ar"  ><Home lang="ar" /></HeaderLayout>
  },
  {
    path: "/ar",
    exact: true,
    component: <HeaderLayout lang="ar" ><Home lang="ar" /></HeaderLayout>
  },
  {
    path: "/travel-stories",
    exact: true,
    component: <HeaderLayout><Layout><TravelStories /></Layout></HeaderLayout>
  }
];


import { lazy } from "react";
import Layout from "../../hoc/Layout";
import HeaderLayout from "../../hoc/HeaderLayout";
const PackageList = lazy(() => import("./containers/PackageList"))
const PackageDetails = lazy(() => import("./containers/PackageDetails"))



export const PackageRoutes = [
  {
    path: "/packages",
    exact: true,
    component: <HeaderLayout><Layout><PackageList /></Layout></HeaderLayout>
  },
  {
    path: "/ar/packages",
    exact: true,
    component: <HeaderLayout lang="ar" ><Layout><PackageList lang="ar" /></Layout></HeaderLayout>
  },
  {
    path: "/packages/details/:id",
    exact: true,
    component: <HeaderLayout  ><Layout><PackageDetails /></Layout></HeaderLayout>

  },
  {
    path: "/ar/packages/details/:id",
    exact: true,
    component: <HeaderLayout lang="ar"><Layout><PackageDetails lang="ar" /></Layout></HeaderLayout>

  },
];
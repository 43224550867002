import { List, ListItem, ListItemPrefix, Popover, PopoverContent, PopoverHandler } from '@material-tailwind/react'
import React, { useState } from 'react'
import arrow from '../../assets/arrow.png'
import menu from '../../assets/menu.png'
import menuWhite from '../../assets/icons/Hamburger Menu.svg';
import arrowWhite from '../../assets/icons/arrow-down.svg';
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

const HeaderMore = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const isHome = location.pathname === '/'
    const [openPopOver, setOpenPopOver] = useState(false)
    const { translation } = useSelector((state) => state.sharedState)

    //    const handlePopoverOpen=()=>{
    //     setOpenPopOver(true)
    //    }
    const handlePopoverClose = () => {
        setOpenPopOver(false)
    }

    const handleListItemClick = (path) => {
        navigate(path);
        handlePopoverClose();
    };
    const terms = useSelector(
        (item) => item?.sharedState?.social?.information?.[0]
    );
    const facebooklink = terms?.facebook
    const instalink = terms?.instagram
    const twitter = terms?.twitter
    const linikedin = terms?.linkedIn
    return (
        <div className="flex items-center gap-4">
            <Popover placement="bottom" open={openPopOver} handler={setOpenPopOver}>
                <PopoverHandler >
                    <div className='flex items-center gap-4 cursor-pointer'>
                        {isHome ?
                            <img className='w-5 h-5' src={menu} alt="" /> :
                            <img className='w-5 h-5' src={menuWhite} alt="" />
                        }
                        <p className={`text-sm font-normal text-center ${isHome ? 'text-black' : 'text-white'}`}>{translation?.more}</p>
                        {isHome ?
                            <img src={arrow} alt="" className='w-4' /> :
                            <img src={arrowWhite} alt="" className='w-4' />
                        }
                    </div>

                </PopoverHandler>
                <PopoverContent className="z-10 flex flex-col items-center mt-6 w-72">
                    <div className="flex items-center justify-between h-12 gap-4 pb-4 mb-4 border-b w-60 border-blue-gray-50">
                        <div>
                            <p className={`text-sm font-normal text-center ${isHome ? 'text-black' : 'text-white'} `}>
                                {translation?.more}
                            </p>
                        </div>
                        <div>
                            <img className='w-6 h-6 cursor-pointer' onClick={handlePopoverClose} src={require('../../assets/close.png')} alt="" />
                        </div>
                    </div>
                    <List className="p-0">
                        <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => handleListItemClick('/general/smart-payment')}>
                            <ListItemPrefix>
                                <img className='w-6 h-6' src={require('../../assets/payment.png')} alt="" />
                            </ListItemPrefix >
                            {translation?.smart_payment}
                        </ListItem>
                        <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => handleListItemClick('/general/settings')}>
                            <ListItemPrefix>
                                <img className='w-6 h-6' src={require('../../assets/Setting.png')} alt="" srcset="" />
                            </ListItemPrefix>
                            {translation?.settings}
                        </ListItem>
                        {/* <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => navigate('/')}>
                            <ListItemPrefix>
                                <img className='w-6 h-6' src={require('../../assets/gift.png')} alt="" />
                            </ListItemPrefix>
                            {language === "ar" ? arabic_translation.gift_cards : 'Gift Cards'}
                        </ListItem> */}
                        <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => handleListItemClick('/general/travel-stories')}>
                            <ListItemPrefix>
                                <img className='w-6 h-6' src={require('../../assets/travelStories.png')} alt="" srcset="" />
                            </ListItemPrefix>
                            {translation?.travel_stories}
                        </ListItem>
                        <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => handleListItemClick('/general/Recomended')}>
                            <ListItemPrefix>
                                <img className='w-6 h-6' src={require('../../assets/travelStories.png')} alt="" srcset="" />
                            </ListItemPrefix>
                            {translation?.recommended_for_you}
                        </ListItem>
                        <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => handleListItemClick('/general/popular-destination')}>
                            <ListItemPrefix>
                                <img className='w-6 h-6' src={require('../../assets/travelStories.png')} alt="" srcset="" />
                            </ListItemPrefix>
                            {translation?.popular_destinations}
                        </ListItem>
                        {/* <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => navigate('/')}>
                            <ListItemPrefix>
                                <img className='w-6 h-6' src={require('../../assets/tips.png')} alt="" srcset="" />
                            </ListItemPrefix>
                            {language === "ar" ? arabic_translation.travel_tips : 'Travel Tips'}
                        </ListItem> */}
                        {/* <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => navigate('/general/manage-booking')}>
                            <ListItemPrefix>
                                <BookingIconSvg color='#9C9C9C' />
                            </ListItemPrefix>
                            {language === "ar" ? arabic_translation.manage_bookings : 'Manage Bookings'}
                        </ListItem> */}
                        {/* <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => navigate('/')}>
                            <ListItemPrefix>
                                <img className='w-6 h-6' src={require('../../assets/travellAssitance.png')} alt="" srcset="" />
                            </ListItemPrefix>
                            {language === "ar" ? arabic_translation.travel_assistance : 'Travel Assistant'}
                        </ListItem> */}

                        <ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => handleListItemClick('/general/help-center')}>
                            <ListItemPrefix>
                                <img className='w-6 h-6' src={require('../../assets/faq.png')} alt="" srcset="" />
                            </ListItemPrefix>
                            {translation?.help_center}
                        </ListItem>

                        {/*<ListItem className='flex items-center h-16 gap-5 text-sm font-normal border-b w-60' onClick={() => navigate('/')}>
                            <ListItemPrefix>
                                <img className='w-6 h-6' src={require('../../assets/rating.png')} alt="" srcset="" />
                            </ListItemPrefix>
                            {language === "ar" ? arabic_translation.rating : 'Rating'}
                        </ListItem> */}
                    </List>
                    <div className='flex items-center justify-between h-12 cursor-pointer w-60'>
                        <div>
                            <p className='text-sm font-normal '>{translation?.social_account}</p>
                        </div>
                        <div className='flex gap-2 '>
                            <Link to={`${facebooklink}`} target='blank'><img className='w-6 h-6' src={require('../../assets/facebook (1).png')} alt="" /></Link>
                            <Link to={`${instalink}`} target='blank'><img className='w-6 h-6' src={require('../../assets/instagram.png')} alt="" /></Link>
                            <Link to={`${twitter}`} target='blank'><img className='w-6 h-6' src={require('../../assets/twitter.png')} alt="" /></Link>
                            <Link to={`${linikedin}`} target='blank'><img className='w-6 h-6' src={require('../../assets/linkedin3.png')} alt="" /></Link>
                        </div>

                    </div>
                </PopoverContent>
            </Popover>
        </div>
    )
}

export default HeaderMore
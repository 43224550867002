import React from 'react'
import { Header } from '../shared'
import FooterTop from '../shared/components/FooterTop'
import Footer from '../shared/components/Footer'

const HeaderLayout = ({ children, lang  }) => {
  return (
    <div>
        <Header lang={lang} />
        {children}
        <FooterTop/>
        <Footer/>
    </div>
  )
}

export default HeaderLayout
import React, { useState } from 'react'
import { RoundTripSvg, OneWaySvg, MultiCitySvg } from '../../../assets/svg';
import { FlightServiceOption } from '../../../shared';
import OneWay from './OneWay';
import RoundTrip from './RoundTrip';
import MultiCity from './MultiCity';
import RecentSearch from './RecentSearch';
import { useDispatch, useSelector } from 'react-redux';
import { setFlightSearch } from '../exploresSlice';
import { format } from 'date-fns';


const FlightBookingCard = ({ lang }) => {
  const dispatch = useDispatch()
  const { flightSearch } = useSelector(state => state.exploresState);
  const { translation } = useSelector(state => state.sharedState)
  const [selectedService, setSelectedService] = useState('roundTrip')
  const handleService = (service) => {
    setSelectedService(service)
    if (service === 'roundTrip') {
      dispatch(setFlightSearch({
        ...flightSearch,
        mode: 'ROUNDTRIP',
        searchList: [flightSearch?.searchList[0]].map((item, index) => ({
          ...item,
          returnDate: format(
            new Date(new Date(item.departureDate).getTime() + 24 * 60 * 60 * 1000),
            "yyyy-MM-dd"
          )
        }))
      }))
    } else if (service === 'oneWay') {
      dispatch(setFlightSearch({
        ...flightSearch,
        mode: 'ONEWAY',
        searchList: [flightSearch?.searchList[0]].map((item, index) => ({
          ...item,
          returnDate: null,
          returnAnchorEl: null
        }))
      }))
    } else {
      dispatch(setFlightSearch({
        ...flightSearch,
        mode: 'MULTICITY',
        searchList: flightSearch?.searchList?.map((item, index) => ({
          ...item,
          departureDate: format(new Date(), "yyyy-MM-dd"),
          fromOpen: false,
          toOpen: false,
        }))
      }))
    }
  }
  return (
    <div className='relative w-full p-4 duration-300 ease-in-out bg-white rounded-lg '>
      <div className='absolute flex items-center gap-4 -top-4 '>
        <FlightServiceOption
          svgColor={selectedService === "oneWay" ? 'white' : '#707581'}
          backgorundColor={selectedService === "oneWay" ? 'bg-orange-400' : 'bg-white'}
          textColor={selectedService === "oneWay" ? 'text-white' : ' text-gray-500'}
          handleService={() => handleService('oneWay')} SvgComp={OneWaySvg}
          service={translation?.one_way}

        />
        <FlightServiceOption
          svgColor={selectedService === "roundTrip" ? 'white' : '#707581'}
          backgorundColor={selectedService === "roundTrip" ? 'bg-orange-400' : 'bg-white'}
          textColor={selectedService === "roundTrip" ? 'text-white' : ' text-gray-500'}
          handleService={() => handleService('roundTrip')} SvgComp={RoundTripSvg}
          service={translation?.round_trip}
        />
        <FlightServiceOption
          svgColor={selectedService === "multiCity" ? 'white' : '#707581'}
          backgorundColor={selectedService === "multiCity" ? 'bg-orange-400' : 'bg-white'}
          textColor={selectedService === "multiCity" ? 'text-white' : ' text-gray-500'}
          handleService={() => handleService('multiCity')} SvgComp={MultiCitySvg}
          service={translation?.multi_city}
        />
      </div>
      {selectedService === "oneWay" && <OneWay lang={lang} />}
      {selectedService === "roundTrip" && <RoundTrip lang={lang} />}
      {selectedService === "multiCity" && <MultiCity lang={lang} />}
      <RecentSearch />
    </div >
  )
}

export default FlightBookingCard